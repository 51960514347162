.ta-services__container.flex {
  height: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-services__inner {
  width: 100%;
  text-align: center;
  padding: 0 28px;
}

.ta-services__icon {
  color: #cb3b43;
  font-size: 48px;
  margin-bottom: 12px;
}

.ta-services__inner .ta-popup__main_title {
  color: #c93d46;
  font-size: 16px;
  margin-bottom: 8px;
}


.ta-services__tooltip {
  color: rgba(80, 90, 94, 0.5);
  font-size: 13px;
}

.ta-services__list-item__icon {
  margin-left: 5px;
}

.ta-services__service {
  margin-bottom: 4px;
}

.ta-service__button {
  width: 24px;
  height: 44px;
  line-height: 44px;
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 0;
}

.ta-service__button.short {
  height: 29px;
}

.ta-service__button.short i {
  transform: translateY(-7px);
}

.ta-service__button.active {
  background: #cb3b43;
  color: #fff;
}

.ta-services__price {
  display: inline-block;
  padding-right: 4px;
}

.ta-services__discounted__price {
  display: inline-block;
  padding-left: 4px;
}

.ta-service__upselling {
  display: none;
}
.ta-services__upselling-button.ta-cta-middle {
  margin-top: 8px;
  padding: 8px 16px;
  position: relative;
}
.ta-services__upselling-button.active {
  text-align: left;
}
.ta-services__upselling-button .ta-service__upselling-button-icon-holder {
  display: none;
}
.ta-services__upselling-button.active .ta-service__upselling-button-icon-holder {
  width: 32px;
  height: 100%;
  display: block;
  background: #cb3b43;
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  border-radius: 0 3px 3px 0;
}
.ta-services__upselling-button.active .ta-service__upselling-button-icon-holder .ta-service__upselling-button-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.ta-service__upselling-message.active {
  font-size: 12px;
  text-align: center;
  padding: 12px 16px;
  margin: 4px 0 0 0;
}

.ta-services__category .ta-section-title {
  line-height: 17px;
}

.ta-services__service .ta-simple-list-item__title-text{
  max-width: calc(100% - 25px);
}

@media only screen and (max-width: 991px) {
  .ta-service__upselling {
    display: block;
  }
}