.ta-weekpicker {
  overflow: hidden;
  display: block;
  background: #202020;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 44px;
  margin: 0 -16px 48px -16px;
}
.ta-weekpicker.default {
  background: #505a5e;
}
.ta-weekpicker .slick-list {
  overflow: visible;
}
.ta-weekpicker .slick-prev {
  width: 44px;
  background: transparent;
  background-image: linear-gradient(to left, rgba(32, 32, 32, 0) 7%, #202020 50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 12px;
  border: 0;
  position: absolute;
  left: -44px;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.ta-weekpicker.default .slick-prev {
  background-image: linear-gradient(to left, rgba(32, 32, 32, 0) 7%, #505a5e 50%);
}
.ta-weekpicker .slick-next {
  width: 44px;
  background: transparent;
  background-image: linear-gradient(to right, rgba(32, 32, 32, 0) 7%, #202020 50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  padding-right: 12px;
  border: 0;
  position: absolute;
  right: -44px;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.ta-weekpicker.default .slick-next {
  background-image: linear-gradient(to right, rgba(32, 32, 32, 0) 7%, #505a5e 50%);
}
.ta-weekpicker .slick-prev.slick-disabled,
.ta-weekpicker .slick-next.slick-disabled {
  display: none !important;
}
.ta-weekpicker .slick-prev i,
.ta-weekpicker .slick-next i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
.ta-weekpicker__slide {
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-top: 16px;
}
.ta-weekpicker.default .ta-weekpicker__slide {
  font-size: 20px;
  font-weight: 300;
  padding-top: 14px;
}
.ta-weekpicker__slide.unavailable {
  opacity: 0.3;
}
.ta-weekpicker__slide.active {
  background: #fff;
  color: #202020;
}
.ta-weekpicker__slide-day {
  font-size: 8px;
  margin-top: 4px;
}
.ta-weekpicker.default .ta-weekpicker__slide-day {
  font-weight: 500;
}

.ta-weekpicker__monthselector { height: 40px; line-height: 40px; background: #fff; border-bottom: 1px solid #f5f5f5; text-align: center; margin: 0 -44px; }
.ta-weekpicker__monthselector-label { display: inline-block; vertical-align: middle; font-size: 16px; font-weight: bold; margin: 0 6px 0 8px; }
.ta-weekpicker__monthselector-prev { display: inline-block; vertical-align: middle; background: transparent; font-size: 12px; border: 0; cursor: pointer; }
.ta-weekpicker__monthselector-next { display: inline-block; vertical-align: middle; background: transparent; font-size: 12px; border: 0; cursor: pointer; }
.ta-weekpicker__monthselector-prev.disabled,
.ta-weekpicker__monthselector-next.disabled { opacity: 0.25; }