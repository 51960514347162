.ta-checkbox {
  display: inline-block;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  min-height: 14px;
  position: relative;
  padding-left: 25px;
  user-select: text;
  z-index: 7;
}

.ta-checkbox.box {
  padding: 0 10px;
  border: 1px solid #505A5E;
  border-radius: 3px;
  font-weight: 500;
  font-size: 11px;
  color: #505A5E;
  line-height: 23px;
  cursor: pointer;
}

.ta-checkbox:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  color: #385f87;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 17px;
  border: solid 1px rgba(80, 90, 94, 0.6);
  border-radius: 2px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.ta-checkbox.box:after {
  display: none;
}

.ta-checkbox.switch {
  padding-left: 39px;
}

.ta-checkbox.switch:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background: #505a5e;
  opacity: 0.5;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all .2s;
}

.ta-checkbox.box:before {
  display: none;
}

.ta-checkbox.switch:after {
  width: 32px;
  border-radius: 10px;
  transition: all .2s;
}

.ta-checkbox.active:after {
  content: "\f00c";
  border-color: #505a5e;
}

.ta-checkbox.box.active {
  background: #CB3B43;
  border-color: #CB3B43;
  color: #fff;
}

.ta-checkbox.switch.active:before {
  background: #769851;
  transform: translateX(14px);
  opacity: 1;
}

.ta-checkbox.switch.active:after {
  content: "";
}

.ta-checkbox.focused:after {
  border-color: #385f87;
}

.ta-checkbox.disabled:after {
  background-color: #e6e6e7;
  border-color: rgba(80, 90, 94, 0.6);
  color: rgba(80, 90, 94, 0.6);
}

.ta-checkbox-field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.ta-checkbox-wrapper.inline {
  display: inline-block;
  padding-right: 10px;
}

.ta-checkbox a {
  color: #505a5e;
  font-weight: 500;
  text-decoration: underline;
}
