.ta-datepicker {
  width: 336px;
  display: block;
  color: #505a5e;
  vertical-align: top;
  padding: 0 40px;
  margin: 0 auto 36px auto;
  position: relative;
}

.ta-datepicker.weekly {
  width: 360px;
}

.ta-datepicker.options-with-text {
  margin-bottom: 0;
}

.ta-datepicker.opened::before {
  height: 48px;
  content: " ";
  background: #d4d6d7;
  border: 1px solid #fff;
  position: absolute;
  left: 36px;
  top: -4px;
  right: 36px;
  z-index: 2;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4), 0 0 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px 3px 0 0;
}

.ta-datepicker.opened::after {
  width: 262px;
  height: 14px;
  content: " ";
  background: #d4d6d7;
  position: absolute;
  left: 37px;
  top: 30px;
  z-index: 11;
}

.ta-datepicker.weekly.opened::after {
  width: 286px;
}

.ta-datepicker .ta-datepicker__nav {
  width: 40px;
  height: 40px;
  display: block;
  color: #505a5e;
  position: absolute;
  top: 0;
  border: 1px solid #d3d6d7;
}

.ta-datepicker .ta-datepicker__nav.ta-datepicker__disabled {
  background: rgba(80, 90, 94, 0.16);
}

.ta-datepicker .ta-datepicker__nav .ta-datepicker__icon {
  width: 20px;
  height: 20px;
  line-height: 19px;
  font-size: 19px;
  text-align: center;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ta-datepicker .ta-datepicker__prev {
  left: 0;
  border-right: none;
  cursor: pointer;
  border-radius: 3px 0 0 3px;
  outline: none;
}

.ta-datepicker .ta-datepicker__next {
  right: 0;
  border-left: none;
  cursor: pointer;
  border-radius: 0 3px 3px 0;
  outline: none;
}

.ta-datepicker .ta-datepicker__main {
  width: 256px;
  height: 40px;
  line-height: 40px;
  display: block;
  background: #fff;
  color: #505a5e;
  font-size: 14px;
  padding: 0 10px;
  border: 1px solid #d3d6d7;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 2;
}

.ta-datepicker__main.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: none;
  border-bottom: none;
  margin-top: 32px;
  cursor: default;
}

.ta-datepicker__main.text .ta-datepicker__main-text__option {
  max-width: 48%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ta-datepicker.weekly .ta-datepicker__main {
  width: 280px;
}

.ta-datepicker.opened .ta-datepicker__main {
  background: #d4d6d7;
  border-color: #d4d6d7;
}

.ta-datepicker .ta-datepicker__main .ta-datepicker__icon {
  font-size: 16px;
  margin-right: 10px;
}

.ta-datepicker .ta-datepicker__main .ta-datepicker__icon-right {
  opacity: 0.64;
  font-size: 16px;
  margin-left: 8px;
}

.ta-datepicker .ta-datepicker__main .ta-datepicker__day {
  font-weight: 500;
}

.ta-datepicker .ta-datepicker__main .ta-calendar__day {
  text-transform: capitalize;
}

.ta-datepicker .ta-datepicker__cnt {
  width: 286px;
  background-color: rgba(255, 255, 255, 0.95);
  border-top: 0;
  padding: 1px;
  position: absolute;
  left: 25px;
  top: 44px;
  z-index: 11;
  border-radius: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4), 0 0 3px 0 rgba(0, 0, 0, 0.5);
}

.ta-datepicker.weekly .ta-datepicker__cnt {
  width: 310px;
}

.ta-datepicker .ta-datepicker__cnt::before {
  height: 2px;
  content: " ";
  background: #d4d6d7;
  position: absolute;
  top: -1px;
  left: 11px;
  right: 11px;
  z-index: 2;
}

/* EXTEND STYLES OF THE DEFAULT DATEPICKER */

.ta-datepicker .react-datepicker-wrapper {
  width: 100%;
  display: block;
}

.ta-datepicker .react-datepicker__input-container {
  display: block;
}

.ta-datepicker .react-datepicker__month-container {
  float: none;
}

.ta-datepicker .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .ta-datepicker .react-datepicker__year-read-view--down-arrow::before, .ta-datepicker .react-datepicker__month-read-view--down-arrow::before, .ta-datepicker .react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #505a5e;
}

.ta-datepicker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .ta-datepicker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #505a5e;
}

.ta-datepicker .react-datepicker {
  width: 100%;
  border: 1px solid #505a5e;
  border-radius: 3px;
  box-shadow: rgba(80, 90, 94, 0.3) 0 0 10px;
}

.ta-datepicker .react-datepicker.datepicker-day, .ta-datepicker .react-datepicker.datepicker-week {
  border: 0;
  box-shadow: none;
}

.ta-datepicker .react-datepicker__header {
  background: transparent;
  color: #505a5e;
  border: 0;
  padding: 0;
}

.ta-datepicker .react-datepicker__current-month {
  height: 40px;
  line-height: 40px;
  background: #d4d6d7;
  color: #505a5e;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.ta-datepicker .react-datepicker__navigation--previous {
  width: 20px;
  height: 20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #505a5e;
  border: 0;
  left: 7px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}

.ta-datepicker .react-datepicker__navigation--previous:before {
  width: 20px;
  line-height: 20px;
  display: block;
  content: "\f104";
  font-size: 14px;
  text-indent: 0;
}

.ta-datepicker .react-datepicker__navigation--next {
  width: 20px;
  height: 20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #505a5e;
  border: 0;
  right: 7px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}

.ta-datepicker .react-datepicker__navigation--next:before {
  width: 20px;
  line-height: 20px;
  display: block;
  content: "\f105";
  font-size: 14px;
  text-indent: 0;
}

.ta-datepicker .react-datepicker__day-name {
  width: 35px;
  height: 12px;
  line-height: 12px;
  opacity: 0.3;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  margin: 2.5px;
}

.ta-datepicker .react-datepicker .react-datepicker__month {
  margin: 0;
}

.ta-datepicker .react-datepicker__day {
  width: 32px;
  height: 32px;
  line-height: 30px;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid transparent;
  margin: 4px;
  border-radius: 3px;
  position: relative;
}

.ta-datepicker .react-datepicker__day.react-datepicker__day--disabled {
  opacity: 0.5;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__day {
  width: 40px;
  height: 32px;
  line-height: 32px;
  margin: 2px 0;
  border-radius: 0;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__day:first-child {
  border-radius: 3px 0 0 3px;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__day:last-child {
  border-radius: 0 3px 3px 0;
}

.ta-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 1;
  color: #d3d6d7;
}

.ta-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 0.5;
}

.ta-datepicker .react-datepicker__day:hover {
  background: transparent;
  color: #385f87;
  border-color: #385f87;
}

.ta-datepicker .react-datepicker__day--disabled::after {
  width: 60%;
  content: " ";
  border-top: 1px solid #505a5e;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ta-datepicker .react-datepicker__day--today {
  background: transparent;
  color: #cb3b43;
  border-color: transparent;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__day--today:not(.react-datepicker__day--selected) {}

.ta-datepicker .react-datepicker__day--today:hover {
  background: transparent;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__week:hover .react-datepicker__day {
  background: #fff;
  color: #385f87;
  border-top: 1px solid #385f87;
  border-bottom: 1px solid #385f87;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__week:hover .react-datepicker__day:first-child {
  border-left: 1px solid #385f87;
  border-radius: 3px 0 0 3px;
}

.ta-datepicker .react-datepicker.datepicker-week .react-datepicker__week:hover .react-datepicker__day:last-child {
  border-right: 1px solid #385f87;
  border-radius: 0 3px 3px 0;
}

.ta-datepicker .react-datepicker__day--selected, .ta-datepicker .react-datepicker__day--selected:hover, .ta-datepicker .react-datepicker.datepicker-week .react-datepicker__week:hover .react-datepicker__day--selected, .ta-datepicker .react-datepicker.datepicker-week .react-datepicker__week:hover .react-datepicker__day--selected:hover {
  background: #385f87;
  border-color: #385f87;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .ta-datepicker {
    width: 100%;
  }
  .ta-datepicker .ta-datepicker__main, .ta-datepicker.weekly .ta-datepicker__main {
    width: 100%;
  }
  .ta-datepicker.weekly {
    width: 100%;
  }
  .ta-datepicker .ta-datepicker__cnt, .ta-datepicker.weekly .ta-datepicker__cnt {
    width: calc(100% - 50px);
  }
  .ta-datepicker.opened::after, .ta-datepicker.weekly.opened::after {
    width: calc(100% - 74px);
  }
}

@media only screen and (max-width: 375px) {
  .ta-datepicker .ta-datepicker__main, .ta-datepicker.weekly .ta-datepicker__main {
    font-size: 13px;
  }
  .ta-datepicker .ta-datepicker__cnt, .ta-datepicker.weekly .ta-datepicker__cnt {
    width: 100%;
    left: 0;
  }
}
