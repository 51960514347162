.ta-progressbar {
  width: 100%;
  height: 5px;
  background: #f4f3f3;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.ta-progressbar__line {
  height: 5px;
  background: #b6babd;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  -ms-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
}
.ta-progressbar__line.step1 {
  width: 20%;
}
.ta-progressbar__line.step2 {
  width: 40%;
}
.ta-progressbar__line.step3 {
  width: 60%;
}
.ta-progressbar__line.step4 {
  width: 80%;
}
.ta-progressbar__line.step5 {
  width: 100%;
}