.ta-accordion {
  user-select: none;
  position: relative;
}

.ta-accordion .ta-accordion__title:after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 13px;
  color: #505a5e;
  transform: translateY(-50%);
}

.ta-accordion.inactive .ta-accordion__title:after {
  display: none;
}

.ta-accordion .ta-accordion__body {
  opacity: 0;
  visibility: hidden;
  /*transform: translateY(-5px);*/
  max-height: 0;
  transition: all 0.2s;
}

.ta-accordion.expand .ta-accordion__title:after {
  content: "\F106"
}

.ta-accordion.expand .ta-accordion__body {
  opacity: 1;
  visibility: visible;
  /*transform: translateY(0);*/
  max-height: 10000px;
}

.ta-accordion__title {
  position: relative;
}

.ta-accordion.has-field-with-error:not(.expand) {
  background: rgba(203, 59, 67, 0.1);
}
