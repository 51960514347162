.ta-payments__credit-card {
  display: flex;
  padding-top: 90px;
  padding-bottom: 16px;
}

.ta-payments__stripe-elements {
  width: 100%;
  margin: auto;
}

.ta-payments__stripe-elements__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  color: #505a5e;
  margin: 10px 0 20px;
  padding: 0 20px;
}

.ta-stripe .StripeElement {
  margin-bottom: 8px;
}

.ta-stripe .ta-form-error {
  margin-top: 0;
}

.ta-stripe__card-element__btn {
  margin-top: 16px;
}
