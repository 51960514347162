.ta-auth-forgotten__container {
  height: 542px;
  display: flex;
  text-align: center;
  padding: 0 28px;
}

.ta-auth-forgotten__inner {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}

.ta-auth-forgotten__field {
  width: 248px;
  margin: 0 auto 16px auto;
}
