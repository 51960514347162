.ta-stripe__card-element .ta-form-control {
  padding: 7px 10px;
}

.ta-stripe-checkout__button {
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: calc(100% - 16px);
  background-color: #cb3b43;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 3px;
}

.ta-stripe-checkout__message {
  margin-top: 10px;
  text-align: center;
}