.ta-footer {
  position: absolute;
  left: 0;
  bottom: -100px;
  right: 0;
  z-index: 10;
  -ms-transition: bottom 0.3s ease;
  -webkit-transition: bottom 0.3s ease;
  -moz-transition: bottom 0.3s ease;
  transition: bottom 0.3s ease;
}

.ta-footer.active {
  bottom: 5px;
}

.ta-footer.active.offline {
  bottom: 36px;
}

.ta-widget-blur .ta-footer {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.ta-footer__shadow {
  height: 10px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  position: absolute;
  left: 0;
  bottom: -100px;
  right: 0;
  z-index: 2;
  -ms-transition: bottom 0.3s ease;
  -webkit-transition: bottom 0.3s ease;
  -moz-transition: bottom 0.3s ease;
  transition: bottom 0.3s ease;
  pointer-events: none;
}

.ta-footer__shadow.active {
  bottom: 0;
}

.ta-footer__shadow.active.with-powered-by {
  bottom: 30px;
}

.ta-footer__shadow.active.with-supported-by {
  bottom: 30px;
}

.ta-footer__shadow.active.with-button {
  bottom: 56px;
}

.ta-footer__poweredby {
  line-height: 12px;
  background: #505a5e;
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 9px 10px;
}

.ta-footer__poweredby.no-round {
  border-radius: 0;
}

.ta-footer__supportedby {
  line-height: 12px;
  background: #f4f4f4;
  color: #505a5e;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 4px 8px 2px 8px;
  position: relative;
  z-index: 3;
}

.ta-footer__supportedby .ta-footer__suportedby_timify {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}

.ta-footer__supportedby .ta-footer__suportedby_timify img {
  height: 28px;
}

.ta-footer__offline {
  width: 100%;
  overflow: hidden;
  line-height: 12px;
  background: #c93d46;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 9px 10px;
  position: absolute;
  left: 0;
  bottom: -50px;
  z-index: 10;
  -ms-transition: bottom 0.3s ease;
  -webkit-transition: bottom 0.3s ease;
  -moz-transition: bottom 0.3s ease;
  transition: bottom 0.3s ease;
}

.ta-footer__offline.active {
  bottom: 5px;
}

.ta-footer__offline i {
  font-size: 12px;
  margin-right: 4px;
}
