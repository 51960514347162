.ta-list {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #505a5e;
  padding: 3px 0;
}

.ta-list a {
  color: #CB3B43;
  text-decoration: underline;
}

.ta-list a:hover {
  text-decoration: none;
}

.ta-list .ta-popup__trigger a {
  color: #505a5e;
}