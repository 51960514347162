.ta-resource-item {
  width: 96px;
  height: 84px;
  display: inline-block;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  margin: 1px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  -ms-transition: background 0.2s ease;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.ta-resource-item.active {
  background: rgba(56, 95, 135, 0.1);
}

.ta-resource-item:hover {
  background: rgba(56, 95, 135, 0.15);
}

.ta-resource-item .ta-avatar {
  width: 48px;
  height: 48px;
  line-height: 49px;
  font-size: 11px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.ta-resource-item .ta-avatar .ta-avatar__image img, .ta-resource-item .ta-avatar .ta-avatar__image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ta-resource-item.active .ta-avatar {
  border: 2px solid #385f87;
  line-height: 44px;
}

.ta-resource-item__name {
  height: 12px;
  position: absolute;
  top: 64px;
  left: 5px;
  right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ta-resource-item__name.single-resource {
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}
