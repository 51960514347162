.ta-auth-changephone__container {
  height: 351px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-auth-changephone__inner {
  width: 100%;
  padding: 0 28px;
}

.ta-auth-changephone__inner .ta-phone-input {
  margin-bottom: 16px;
}

.ta-auth-changephone__buttons {
  text-align: center;
  margin: 0 -4px;
}

.ta-auth-changephone__buttons .ta-cta-fluid {
  margin: 0 4px;
}
