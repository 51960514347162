.ta-auth__container {
  display: flex;
  padding: 8px;
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.ta-auth__inner {
  width: 100%;
  padding: 0;
  margin: auto;
}

.ta-auth__title {
  color: #505a5e;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 8px;
}

.ta-auth__text {
  line-height: 20px;
  color: #505a5e;
  font-size: 14px;
  text-align: center;
  padding: 0 8px;
  margin-bottom: 32px;
}

.ta-auth__no_round {
  border-radius: 0;
}

.ta-auth .ta-lined-title {
  margin: 8px 0;
}
