.ta-slide-selector {
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 0 8px 0;
  position: relative;
}

.ta-slide-selector:before {
  width: 100px;
  height: 30px;
  content: "";
  background: -moz-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  background: linear-gradient(to right, rgba(244, 243, 243, 1) 0%, rgba(244, 243, 243, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
  position: absolute;
  top: 0;
  left: 25px;
  z-index: 5;
}

.ta-slide-selector:after {
  width: 100px;
  height: 30px;
  content: "";
  background: -moz-linear-gradient(left, rgba(244, 243, 243, 0) 0%, rgba(244, 243, 243, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 243, 243, 0) 0%, rgba(244, 243, 243, 1) 100%);
  background: linear-gradient(to right, rgba(244, 243, 243, 0) 0%, rgba(244, 243, 243, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( endColorstr='#f4f3f3', startColorstr='#00ffffff', GradientType=0);
  position: absolute;
  top: 0;
  right: 25px;
  z-index: 5;
}

.ta-slide-selector .btn-prev {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 1px 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.ta-slide-selector .btn-next {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 1px 0 0 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.ta-slide-selector__item {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  display: inline-block;
  padding: 0 20px;
  height: 30px;
  max-width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-slide-selector__item span {
  display: inline-block;
  width: 11px;
  height: 11px;
  line-height: 11px;
  background: #cb3b43;
  border-radius: 11px;
  color: #fff;
  font-size: 8px;
  font-weight: 500;
  margin-left: 4px;
  transform: translateY(-2px);
}

.ta-slide-selector__item.active {
  border-bottom: 3px solid #385f87;
}

.ta-slide-selector__item.prev {
  position: absolute;
  top: 0;
  left: 15px;
}

.ta-slide-selector__item.next {
  position: absolute;
  top: 0;
  right: 15px;
}
