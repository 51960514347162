.ta-address {
  position: relative;
}

.ta-address__label {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 0 10px;
  background: #e5e7e7;
  border-radius: 0 0 3px 3px;
  font-size: 12px;
  font-weight: 300;
  color: #505a5e;
  opacity: 0.8;
  z-index: 5;
  margin-bottom: 8px;
}

.ta-address__map {
  width: 100%;
  height: 186px;
  line-height: 186px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #999;
  position: relative;
  z-index: 1;
}
