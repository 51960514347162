.ta-icon-list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 24px;
}

.ta-icon-list li {
  color: #777;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
  position: relative;
}

.ta-icon-list li:after {
  content: "\f058";
  color: #777;
  font-size: 14px;
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  position: absolute;
  left: -24px;
  top: 3px;
  z-index: 3;
}
