.ta-textarea {
  position: relative;
}

.ta-textarea__limit {
  position: absolute;
  bottom: 7px;
  right: 7px;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
}