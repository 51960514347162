.ta-conflict__container {
  height: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-conflict__inner {
  width: 100%;
  text-align: center;
  padding: 0 28px;
}

.ta-conflict__icon {
  color: #cb3b43;
  font-size: 48px;
  margin-bottom: 12px;
}

.ta-conflict__inner .ta-popup__main_title {
  color: #c93d46;
  font-size: 16px;
  margin-bottom: 8px;
}

.ta-conflict__already-booked .ta-conflict__icon {
  color: #505a5e;
}

.ta-conflict__already-booked .ta-popup__main_title {
  color: #505a5e;
}

.ta-conflict__signed {
  margin-top: 24px;
}

.ta-conflict__text {
  margin-bottom: 16px;
}