.ta-hover-popup {
  position: relative;
  display: inline-block;
}

.ta-hover-popup__content {
  position: absolute;
  /* width: 200px; */
  width: 80%;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  color: #505a5e;
  font-size: 13px;
  font-weight: 400;
  padding: 10px;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,.4));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,.4));
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}

.ta-hover-popup__content.auto-size {
  width: auto;
  white-space: nowrap;
}

.ta-hover-popup.is-hover .ta-hover-popup__content {
  opacity: 1;
  visibility: visible;
}

.ta-hover-popup.is-hover.disabled .ta-hover-popup__content {
  opacity: 0;
  visibility: hidden;
}

.ta-hover-popup__content.top {
  bottom: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.ta-hover-popup__content.left {
  right: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
}

.ta-hover-popup__content.right {
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
}

.ta-hover-popup__content.bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.ta-hover-popup__content:after {
  content: "";
  border: 8px solid transparent;
  position: absolute;
  pointer-events: none;
}

.ta-hover-popup__content.top:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top-color: #fff;
}

.ta-hover-popup__content.left:after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-left-color: #fff;
}

.ta-hover-popup__content.right:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-right-color: #fff;
}

.ta-hover-popup__content.bottom:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-color: #fff;
}
