.ta-address-input .ta-form-control.has-suggestions {
  border-radius: 3px 3px 0 0;
}

.ta-address-input__autocomplete-dropdown {
  border: 1px solid #385f87;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  margin-top: 1px;
  padding: 5px;
  border-radius: 0 0 3px 3px;
  transition: all 0.2s;
  margin-bottom: 10px;
}

.ta-address-input__suggestion-item {
  border-bottom: 1px dashed #385f87;
  cursor: pointer;
  position: relative;
  padding: 5px;
  line-height: 16px;
}

.ta-address-input__suggestion-item:last-child {
  border-bottom: none;
}

.ta-address-input__suggestion-item.active {
  background-color: rgba(56, 95, 135, 0.1);
  color: #385f87;
}

.ta-address-input .ta-bordered-box {
  margin-top: 10px;
  margin-bottom: 0;
}

.ta-address-input__map {
  height: 180px;
  overflow: hidden;
  border-radius: 3px;
}

.ta-address-input .ta-list .required {
  color: #cb3b43;
}
