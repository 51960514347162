.ta-note-box {
  width: 100%;
  background: #f4f3f3;
  border-top: 1px solid #d3d6d7;
  padding: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 0 0 3px 3px;
}

.ta-note-box .ta-note-box__container {
  overflow: hidden;
  -ms-transition: max-height 0.5s ease;
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
}

.ta-note-box__switcher {
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: block;
  background: #d3d6d7;
  font-size: 14px;
  text-align: center;
  position: absolute;
  right: 8px;
  top: -24px;
  z-index: 2;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  -ms-transition: background 0.2s ease;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.ta-note-box__switcher:hover {
  background: #afb4b6;
}

.ta-note-box .ta-dotted-title {
  font-size: 14px;
  font-weight: 500;
  border: 0;
  margin: 0 0 8px 0;
  padding: 0;
}

.ta-note-box .ta-text-box {
  margin: 0 0 16px 0;
}
