.ta-phone-input {
  border: 1px solid #969C9E;
  border-radius: 3px;
  height: 32px;
  line-height: 30px;
  position: relative;
}

.ta-phone-input .ta-form-control::-webkit-outer-spin-button,
.ta-phone-input .ta-form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ta-phone-input .ta-form-control {
  -moz-appearance: textfield;
}

.ta-phone-input.active {
  border-radius: 3px 3px 0 0;
}

.ta-phone-input.top.active {
  border-radius: 0 0 3px 3px;
}

.ta-phone-input__country-selector {
  position: relative;
}

.ta-phone-input__selected-country {
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid #969C9E;
  height: 32px;
  line-height: 32px;
  width: 52px;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  transition: all 0.2s;
}

.ta-form-group.ta-phone-input-group.filled .ta-phone-input.active .ta-phone-input__selected-country, .ta-phone-input.active .ta-phone-input__selected-country {
  background: rgb(235, 239, 243);
  border-color: #385f87;
  border-radius: 3px 0 0 0;
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-form-group.ta-phone-input-group.filled .ta-phone-input.top.active .ta-phone-input__selected-country, .ta-phone-input.top.active .ta-phone-input__selected-country {
  border-radius: 0 0 0 3px;
}

.ta-phone-input__selected-country:before {
  content: "\f0dc";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 9px;
  font-size: 11px;
  color: #505a5e;
}

.ta-phone-input__selected-country img {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 16px;
  background: #ccc;
  font-size: 8px;
  text-align: center;
  color: #999;
  display: block;
  position: absolute;
  top: 50%;
  left: 9px;
  transform: translateY(-50%);
}

.ta-phone-input__countries {
  position: absolute;
  top: 32px;
  left: -1px;
  right: -1px;
  overflow: auto;
  border: 1px solid rgba(80, 90, 94, 0.6);
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  z-index: 100;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  padding: 5px;
  border-radius: 0 0 3px 3px;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 0.2s;
  margin-bottom: 10px;
}

.ta-phone-input.top .ta-phone-input__countries {
  top: auto;
  bottom: 2px;
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.ta-phone-input__countries li {
  border-bottom: 1px dashed #385f87;
  cursor: pointer;
  position: relative;
  padding: 0 5px 0 28px;
  height: 30px;
  line-height: 28px;
  direction: ltr;
}

.ta-phone-input__countries li.active {
  color: #505a5e;
  font-weight: normal;
}

.ta-phone-input__countries li:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-phone-input__countries li:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-phone-input__countries li:hover {
  background-color: rgba(56, 95, 135, 0.1);
  color: #385f87;
}

.ta-phone-input__countries li:last-child {
  border-bottom: none;
  margin-bottom: 5px;
}

.ta-phone-input__countries li span {
  margin-left: 5px;
  font-size: 10px;
  display: inline-block;
}

.ta-phone-input__countries li img {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 16px;
  background: #ccc;
  font-size: 8px;
  text-align: center;
  color: #999;
  display: block;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}

.ta-phone-input.active .ta-phone-input__countries {
  border-color: #385f87;
  visibility: visible;
  opacity: 1;
  height: 200px;
}

.ta-phone-input__country_code {
  position: absolute;
  top: 0;
  left: 51px;
  color: #999999;
  font-weight: 300;
  font-size: 13px;
  padding-left: 10px;
  cursor: default;
}

.ta-phone-input .ta-form-control {
  border: none;
  margin-left: 51px;
  width: calc(100% - 51px);
  height: 30px;
  line-height: 28px;
  padding-left: 61px;
}

.ta-form-group.ta-phone-input-group label {
  left: 116px;
}

.ta-form-group.ta-phone-input-group.focused label, .ta-form-group.ta-phone-input-group.filled label {
  left: 66px;
}

.ta-form-group.ta-phone-input-group.filled .ta-phone-input__selected-country, .ta-form-group.ta-phone-input-group.filled .ta-phone-input {
  border-color: #505a5e;
}

.ta-form-group.ta-phone-input-group.focused .ta-phone-input__selected-country, .ta-form-group.ta-phone-input-group.focused .ta-phone-input {
  border-color: #385f87;
}
