.ta-online-payment {
  padding-top: 100px;
}

.ta-online-payment__stripe-button {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  border: solid 1px #2c2e2f;
  background-color: #fff;
  margin-bottom: 8px;
  padding: 8px;
}

.ta-widget.fullscreen .ta-online-payment-buttons__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ta-widget.fullscreen .ta-online-payment__stripe-button {
  max-width: 750px;
  min-width: 518px;
}

.ta-online-payment__stripe-button:hover {
  filter: brightness(0.95);
}

.ta-online-payment__stripe-button__methods > * {
  border-radius: 3px;
  height: 14px;
  width: 24px;
  margin-right: 4px;
}

.ta-online-payment__stripe-button__methods > :last-child {
  margin-right: 0;
}

@media screen and (max-width: 530px) {
  .ta-widget.fullscreen .ta-online-payment__stripe-button {
    min-width: 300px;
  }
}