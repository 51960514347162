.ta-next-step {
  background: #fff;
  padding: 8px;
  border-radius: 0 0 3px 3px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -150px;
  z-index: 4;
  -ms-transition: bottom 0.3s ease;
  -webkit-transition: bottom 0.3s ease;
  -moz-transition: bottom 0.3s ease;
  transition: bottom 0.3s ease;
}

.ta-next-step.has-back-button {
  display: flex;
}

.ta-next-step.has-back-button > * {
  flex: 1;
}

.ta-next-step.has-back-button button {
  padding: 0;
  height: 40px;
}

.ta-next-step.has-back-button .ta-footer__back-button {
  margin-right: 5px;
}

.ta-next-step.active {
  bottom: 0;
}
