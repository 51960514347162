.ta-cookie-conset__links_container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.ta-cookie-consent__container {
  display: flex;
  padding: 16px;
  background-color: #d3d9de;
  z-index: 11;
  position: absolute;
  left: 0;
  right: 0;
  -ms-transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  -moz-transition: top 0.3s ease;
  transition: top 0.3s ease;
  top: 100%;
  transform: translateY(-100%);
  overflow-y: auto;
}

.ta-cookie-consent__buttons_container {
  display: flex;
}

.ta-cookie-consent__message {
  margin-bottom: 4px;
  max-height: 150px;
  overflow-y: auto;
  position: relative;
  padding-bottom: 8px;
}

.ta-cookie-conset__links_container:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  z-index: 990;
  background: linear-gradient(to bottom, rgba(211,217,221,0) 0%, rgba(211,217,221,1) 90%);
}

.ta-cookie-consent__buttons_container .ta-btn {
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  height: fit-content;
  min-width: 60px;
}

.ta-cookie-consent__buttons_container .ta-btn.ta-btn-ghost {
  margin-right: 8px;
  border: solid 1px #505a5e;
  color: #505a5e;
  background-color: transparent;
}

.ta-cookie-consent__buttons_container .ta-btn.ta-btn-primary {
  background-color: #cb3b43;
  color: #fff;
}

.ta-cookie-consent__buttons_container .ta-btn.ta-btn-primary:hover {
  background: #ab3138;
}

.ta-cookie-consent__buttons_container .ta-btn.ta-btn-ghost:hover {
  border-color: #83949b;
}

.ta-cookie-consent__message {
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
}

.ta-cookie-conset__links_container .ta-cookie-conset__link {
  margin: 4px 8px 0 0;
  font-size: 12px;
  line-height: 1.33;
  color: #505a5e;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.ta-cookie-conset__links_container .ta-cookie-conset__link.bold{
  font-weight: 500;
}

.ta-cookie-consent__container.full {
  display: block;
  padding: 0;
  top: 0;
  -ms-transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  -moz-transition: top 0.3s ease;
  transition: top 0.3s ease;
  overflow-y: scroll;
  transform: initial;
  bottom: 0;
}

.ta-cookie-consent__container.full .ta-cookie-consent__header {
  display: flex;
  height: 48px;
  width: 100%;
  padding: 16px;
  background-color: #505a5e;
  color: white;
  position: sticky;
  top: 0;
}

.ta-cookie-consent__container.full  .ta-cookie-consent__header_title {
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.ta-cookie-consent__container.full  .ta-cookie-consent__header__back_button {
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
  color: #fff;
  cursor: pointer;
}

.ta-cookie-consent__content {
  flex: 1;
  color: #505a5e;
}

.ta-cookie-consent__content_intro, .ta-cookie-consent__content_description {
  font-size: 12px;
  line-height: 1.33;
}

.ta-cookie-consent__content_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.ta-cookie-consent__content_link {
  font-size: 12px;
  line-height: 1.33;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 16px;
}

.ta-cookie-consent__content_link_container {
  display: flex;
}

.ta-cookie-consent__content_intro {
  padding-bottom: 16px;
}

.ta-cookie-consent__content_title, .ta-cookie-consent__content_description {
  padding-bottom: 8px;
}

.ta-cookie-consent__container.full .ta-cookie-consent__content {
  padding: 16px;
}

.ta-cookie-consent__content_link_addon {
  margin-right: 8px;
}

.ta-divider {
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background-color: #a8b3bd;
}

.ta-cookie-consent__content .ta-checkbox.switch:before {
  z-index: 1;
}

.ta-cookie-consent__cookie_title {
  font-weight: bold;
}

.ta-cookie-consent__content .ta-bordered-box {
  background-color: #ebeff3;
  color: #505a5e;
  padding: 16px;
}

.ta-cookie-consent__content .ta-form-section {
  margin-bottom: 8px;
}
.ta-cookie-consent__content .ta-form-section .ta-col {
  vertical-align: baseline;
}

.ta-cookie-consent__content .ta-checkbox-wrapper {
  float: right;
}

.ta-cookie-consent__cookie_switch_label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
}

.ta-cookie-consent__cookie_timestamp {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  text-align: right;
}

.ta-cookie-consent__cookie_description {
  margin: 8px 0 0;
  font-size: 12px;
  line-height: 1.33;
}

@media (max-width: 720px) {
  .ta-cookie-conset__links_container {
    flex-direction: column-reverse;
  }

  .ta-cookie-consent__buttons_container .ta-btn {
    height: 35px;
    width: 50%;
  }
  
  .ta-cookie-consent__buttons_container {
    margin-bottom: 8px;
  }

  .ta-cookie-consent__buttons_container.links {
    flex-direction: column;
  }

  .ta-cookie-consent__buttons_container.links .ta-cookie-conset__link {
    text-align: left;
  }
  .ta-cookie-consent__buttons_container.links .ta-cookie-conset__link:last-child {
    margin-top: 8px;
  }
}