.ta-resources-fixed__container {
  overflow: hidden;
  text-align: center;
  margin-bottom: 24px;
}

.ta-resources-fixed__many .ta-resources-fixed__container {
  overflow: visible;
}

.ta-resources-fixed__container:before,
.ta-resources-fixed__container:after {
  width: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.26);
  content: "";
  display: inline-block;
  position: relative;
  top: -46px;
}

.ta-resources-fixed__container:before {
  right: -1.5em;
  margin-left: -50%;
}

.ta-resources-fixed__container:after {
  left: -1.5em;
  margin-right: -50%;
}

.ta-resources-fixed__many .ta-resources-fixed__container:before {
  right: -50%;
}

.ta-resources-fixed__many .ta-resources-fixed__container:after {
  left: -50%;
}

.ta-resources-fixed.ta-resources-fixed__nochoose {
  margin-top: -24px;
}

.ta-resources-fixed.ta-resources-fixed__nochoose.nomargin {
  margin-top: 0px;
}

.ta-resources-fixed .ta-resource-item {
  width: 76px;
  cursor: default;
  position: relative;
  z-index: 2;
}

.ta-resources-fixed.ta-resources-fixed__many .ta-resource-item {
  width: 60px;
}

.ta-resources-fixed .ta-resource-item:after {
  content: '+';
  font-size: 12px;
  position: absolute;
  right: -4px;
  top: 32px;
  z-index: 2;
}

.ta-resources-fixed.ta-resources-fixed__many .ta-resource-item:after {
  display: none;
}

.ta-resources-fixed .ta-resource-item:last-child:after {
  display: none;
}

.ta-resources-fixed .ta-resource-item .ta-avatar {
  width: 56px;
  height: 56px;
  line-height: 57px;
}

.ta-resources-fixed .ta-resource-item__name {
  font-weight: 500;
  top: 74px;
  left: -220px;
  right: -220px;
  text-align: center;
}

.ta-resources-fixed.ta-resources-fixed__nochoose .ta-resource-item__name {
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  white-space: nowrap;
  width: max-content;
}

.ta-resources-fixed .ta-resource-item:hover {
  background: transparent !important;
}

.ta-resources-fixed .ta-resources-empty {
  width: 60px;
  height: 84px;
  display: inline-block;
  vertical-align: top;
  margin: 1px;
  position: relative;
  z-index: 2;
}

.ta-resources-fixed .ta-resources-empty .ta-resources-empty__cnt {
  width: 56px;
  height: 56px;
  line-height: 56px;
  background: #8b8b8b url('/images/avatar-overlay.svg') 0 0 no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  ;
  border-radius: 28px;
  position: absolute;
  top: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ta-resources-fixed .ta-resources-empty .ta-resources-empty__cnt .fas {
  font-size: 16px;
  margin-right: 4px;
  vertical-align: middle;
}

.ta-resources-fixed .ta-hover-popup {
  width: 60px;
  display: inline-block;
  vertical-align: top;
}

.ta-resources-fixed .ta-hover-popup .ta-hover-popup__content {
  text-align: left;
}

.ta-resources-fixed .ta-hover-popup .ta-resources-empty__popup {
  font-size: 12px;
  font-weight: bold;
  margin: 2px 0;
}

.ta-resources-fixed__title {
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
}