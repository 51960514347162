.ta-message {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  max-height: 0;
  padding: 0 15px;
  margin: 0;
  opacity: 0;
  color: #505a5e;
  transition: all 0.3s;
}

.ta-message.active {
  max-height: 500px;
  opacity: 1;
  padding: 15px;
  margin-bottom: 10px;
}

.ta-message a:not(.ta-btn) {
  text-decoration: underline;
  color: #505a5e;
}

.ta-message__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px;
  font-size: 16px;
  color: #505a5e;
  cursor: pointer;
}

.ta-message__icon {
  position: absolute;
  top: 50%;
  left: 17px;
  transform: translateY(-50%);
}

.ta-message__title {
  font-size: 14px;
  font-weight: 500;
}

.ta-message__title i {
  margin-right: 8px;
}

.ta-message__content {
  font-size: 13px;
  font-weight: 300;
  margin-top: 8px;
  color: #505a5e;
  line-height: 18px;
}

.ta-message__content.no-label {
  padding-left: 25px;
  margin-top: 0;
}

.ta-message__content.no-label.no-icon {
  padding-left: 0;
}

.ta-message__content p {
  margin-bottom: 8px;
}

.ta-message.type-success {
  background: rgba(118, 152, 81, 0.15);
}

.ta-message.type-success .ta-message__title {
  color: #769851;
}

.ta-message.type-success .ta-message__content {
  color: #769851;
}

.ta-message.type-warning {
  background: rgba(235, 182, 93, 0.15);
}

.ta-message.type-warning .ta-message__title {
  color: #505a5e;
}

.ta-message.type-error {
  background: rgba(203, 59, 67, 0.15);
}

.ta-message.type-error .ta-message__title {
  color: #cb3b43;
}

.ta-message.type-neutral {
  background: #fff;
}

.ta-message.big {
  text-align: center;
}

.ta-message.big .ta-message__title i {
  font-size: 48px;
  display: block;
  margin-bottom: 10px;
}

.ta-message.right {
  margin-left: -100px;
}
