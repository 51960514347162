.ta-simple-list-item {
  background: #fff;
  color: #505a5e;
  border-radius: 3px;
  border: 1px solid #fff;
  margin-bottom: 8px;
  padding: 10px;
  position: relative;
  min-height: 40px;
  cursor: pointer;
}

.ta-simple-list-item.no-pointer {
  cursor: default;
}

.ta-simple-list-item.draggable {
  cursor: grab;
}

.ta-sortable-list__item.dragging .ta-simple-list-item {
  opacity: 0;
  margin-bottom: 0;
}

.ta-simple-list-item:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 5px;
  border-radius: 3px 0 0 3px;
}

.ta-simple-list-item:hover:after {
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px 0 0 2px;
}

.ta-simple-list-item:hover {
  background: #f6f8fa;
  border-color: #505a5e;
}

.ta-simple-list-item.stalled,
.ta-simple-list-item.green,
.ta-simple-list-item.light-green,
.ta-simple-list-item.aqua,
.ta-simple-list-item.light-blue,
.ta-simple-list-item.purple,
.ta-simple-list-item.red,
.ta-simple-list-item.course,
.ta-simple-list-item.violet,
.ta-simple-list-item.dusk,
.ta-simple-list-item.grey,
.ta-simple-list-item.breaks,
.ta-simple-list-item.ocher,
.ta-simple-list-item.orange,
.ta-simple-list-item.brown,
.ta-simple-list-item.wood,
.ta-simple-list-item.default,
.ta-simple-list-item.cancelled,
.ta-simple-list-item.inactive,
.ta-simple-list-item.active {padding-left: 15px;}

.ta-simple-list-item.stalled:after {background: #ecd007;}
.ta-simple-list-item.active:after {background: #769851;}
.ta-simple-list-item.inactive:after {background: rgba(182, 186, 189, 0.5);}
.ta-simple-list-item.cancelled:after {background: #cb3b43;}
.ta-simple-list-item.course-booking:after {background: #b26492;}
.ta-simple-list-item.single-booking:after {background: #385f87;}
.ta-simple-list-item.title-booking:after {background: #ebb65d;}

.ta-simple-list-item.booker-customer:after {background: #385f87;}
.ta-simple-list-item.normal-customer:after {background: #f79a83;}

.ta-simple-list-item__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
  position: relative;
}

.ta-simple-list-item__title-text {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.ta-simple-list-item__title-text.ta-simple-list-item__with-children {
  max-width: calc(100% - 100px);
}

.ta-simple-list-item__title-icon {
  margin-left: 8px;
  display: inline-block;
  max-width: 100px;
  position: relative;
  top: 0;
  vertical-align: top;
}

.ta-simple-list-item__content {
  line-height: 17px;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.8;
}

.ta-simple-list-item__content i {
  font-size: 10px;
  position: relative;
  margin-right: 3px;
}

.ta-simple-list-item__content a,
.ta-simple-list-item__description a {
  color: #cb3b43;
  text-decoration: underline;
}

.ta-simple-list-item__content a:hover,
.ta-simple-list-item__description a:hover {
  text-decoration: none;
}

.ta-simple-list-item__description em,
.ta-simple-list-item__description i {
  font-style: italic;
}

.ta-simple-list-item__description b {
  font-weight: 500;
}

.ta-simple-list-item__buttons {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}
