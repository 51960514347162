.ta-auth-enforce-psw-change__login {
  height: 100%;
  display: flex;
  padding: 0 28px;
}

.ta-auth-enforce-psw-change__inner {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}

.ta-auth-enforce-psw-change__login .ta-row {
  margin-bottom: 14px;
}

.ta-auth-enforce-psw-change__inner .ta-form-error {
  margin: 10px 0;
}

.ta-auth-enforce-psw-change__inner .ta-message .ta-cta-primary {
  text-decoration: none;
  color: #fff;
  margin-top: 10px;
  margin-left: 33%;
}

.ta-auth-enforce-psw-change__inner .ta-message .no-label p {
  text-align: center;
}

.ta-auth-enforce-psw-change .password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}
