.ta-availability {
  padding-top: 36px;
}

.ta-availability.active.has-button {
  bottom: 60px;
}

.ta-availability-header-space { width: 70%; visibility: hidden; }

.ta-availability__timezones {
  width: 336px;
  margin: 0 auto 16px auto;
}

.ta-availability .ta-hover-popup {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ta-availability__timezones {
    width: 100%;
  }
}
