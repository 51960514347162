.ta-upselling__container {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-radius: 0 0 3px 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.26);
}

.ta-widget.fullscreen.with-upselling .ta-upselling__container {
  z-index: 3;
}

.ta-upselling__header {
  line-height: 1.14;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 16px;
  position: relative;
}

.ta-upselling__footer {
  line-height: 1.33;
  background-color: rgba(246, 247, 249, 0.8);
  display: none;
  flex-direction: row;
  font-size: 12px;
  text-align: center;
  padding: 12px 16px;
  position: relative;
  transition: height ease 0.5s, padding ease 0.5s;
}

.ta-upselling__footer.active {
  display: flex;
}

.ta-upselling__body {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 0 16px;
  position: relative;
}

.ta-upselling__body .ta-simple-list-item {
  margin-bottom: 2px;
}

.ta-upselling__button {
  width: 24px;
  height: 44px;
  line-height: 44px;
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 0;
}

.ta-upselling__button.active {
  background: #cb3b43;
  color: #fff;
}

.ta-upselling__service-disable {
  opacity: 0.32;
}

.ta-upselling__inner {
  width: 100%;
  display: block;
  position: relative;
}

.ta-upselling__buttons {
  display: none;
}

@media only screen and (max-width: 990px) {
  .ta-upselling__container {
    width: 100%;
    height: calc(100% - 64px);
    display: none;
    top: 64px;
    z-index: 2;
  }
  .ta-upselling__container.open-mobile {
    display: flex;
  }

  .ta-upselling__container::before {
    height: 64px;
    background: rgba(255, 255, 255, 0.96);
    content: ' ';
    position: absolute;
    top: -64px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .ta-upselling__container::after {
    height: 30px;
    content: ' ';
    position: absolute;
    left: -7px;
    top: 0;
    right: 0;
    z-index: 2;
    box-shadow: 0px -10px 7px -10px rgba(0, 0, 0, 0.26);
  }

  .ta-upselling__footer {
    display: flex;
  }

  .ta-upselling__footer .ta-upselling__message {
    display: none;
  }

  .ta-upselling__footer .ta-upselling__buttons {
    display: block;
    position: relative;
  }

  .ta-upselling__footer .ta-upselling__buttons::after {
    content: ' ';
    display: block;
    clear: both;
  }

  .ta-upselling__footer .ta-upselling__buttons button:first-child {
    width: calc(50% - 4px);
    float: left;
  }

  .ta-upselling__footer .ta-upselling__buttons button:last-child {
    width: calc(50% - 4px);
    float: right;
  }
}