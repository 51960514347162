.ta-loading-placeholder__header {
  height: 48px;
  background: linear-gradient(-90deg, #f6f7f9, #e6e8eb, #f8f8fa);
  background-size: 400% 400%;
  -webkit-animation: Gradient 3s ease infinite;
  -moz-animation: Gradient 3s ease infinite;
  animation: Gradient 3s ease infinite;
  border-radius: 5px 5px 0 0;
}

.ta-loading-placeholder__boxes {
  padding: 16px;
}

.ta-loading-placeholder__box {
  height: 54px;
  background: linear-gradient(-90deg, #f6f7f9, #e6e8eb, #f8f8fa);
  background-size: 400% 400%;
  -webkit-animation: Gradient 3s ease infinite;
  -moz-animation: Gradient 3s ease infinite;
  animation: Gradient 3s ease infinite;
  margin-bottom: 4px;
  border-radius: 5px;
}

.ta-loading-placeholder__box.opacity {
  opacity: 0.7;
}

.ta-loading-placeholder__box.opacity-more {
  opacity: 0.45;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.ta-loading-placeholder__box.small {
  width: 100px;
  height: 20px;
}

.ta-loading-placeholder__box.medium {
  width: 110px;
  height: 20px;
}

.ta-loading-placeholder__box.large {
  width: 220px;
  height: 20px;
}

.ta-loading-placeholder__space {
  height: 12px;
}
