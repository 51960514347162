.ta-lined-title {
  line-height: 12px;
  overflow: hidden;
  color: rgba(80, 90, 94, 0.7);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.ta-lined-title .ta-lined-title__label {
  display: inline-block;
  position: relative;
}

.ta-lined-title .ta-lined-title__label:before, .ta-lined-title .ta-lined-title__label:after {
  width: 600px;
  height: 6px;
  content: "";
  border-bottom: 1px solid #d4d6d7;
  position: absolute;
  top: 0;
}

.ta-lined-title .ta-lined-title__label:before {
  margin-right: 8px;
  right: 100%;
}

.ta-lined-title .ta-lined-title__label:after {
  margin-left: 8px;
  left: 100%;
}
