.ta-single-slider__content {
  display: table;
  width: 100%;
  vertical-align: bottom;
  margin-top: 20px;
}

.ta-single-slider__left-label {
  display: table-cell;
  padding-right: 12px;
  font-size: 11px;
  font-weight: 500;
  color: #505a5e;
  transform: translateY(-3px);
}

.ta-single-slider__right-label {
  display: table-cell;
  padding-left: 12px;
  font-size: 11px;
  font-weight: 500;
  color: #505a5e;
  transform: translateY(-3px);
}

.ta-single-slider__body {
  position: relative;
  display: table-cell;
  width: 100%;
  position: relative;
}

.ta-single-slider__body:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #c9c8c6;
  position: absolute;
  left: -2px;
  bottom: 6px;
  z-index: 1;
}

.ta-single-slider__body:after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: #c9c8c6;
  position: absolute;
  right: -2px;
  bottom: 6px;
  z-index: 1;
}

.ta-single-slider .rc-slider-handle {
  background: #385f87;
  border: none;
  width: 11px;
  height: 11px;
  margin-left: -6px;
  z-index: 2;
}

.ta-single-slider .rc-slider-handle:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  border: 1px solid #385f87;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.ta-single-slider .rc-slider {
  height: 12px;
}

.ta-single-slider .rc-slider-step {
  height: 1px;
}

.ta-single-slider .rc-slider-rail {
  height: 1px;
  background: transparent;
  border-bottom: 1px dashed #c9c8c6;
}

.ta-single-slider .rc-slider-track {
  height: 1px;
  background: transparent;
  border-bottom: 1px dashed #385f87;
}
