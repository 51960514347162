.ta-title {
  position: relative;
  border-bottom: 1px dashed #919191;
  font-size: 17px;
  font-weight: 300;
  color: #505a5e;
  margin: 20px 0 15px;
}

.ta-title.small {
  margin: 0 0 4px 0;
  font-size: 13px;
}

.ta-title__label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  padding: 7px 0;
  padding-right: 60px;
  line-height: 15px;
}

.ta-title.small .ta-title__label {
  font-weight: 500;
  padding: 5px 0;
}

.ta-title__content {
  position: absolute;
  right: 0;
  bottom: 2px;
}

.ta-title__content .ta-btn {
  height: 25px;
  line-height: 23px;
  margin-left: 5px;
}

.ta-title__content .ta-popup__trigger {
  margin-left: 5px;
}

.ta-title__content .ta-popup__trigger .ta-btn {
  margin-left: 0;
}

.ta-title__content .ta-btn-icon-only {
  width: 25px;
}

.ta-title__link {
  font-size: 12px;
  font-weight: 500;
  color: #505a5e;
  opacity: 0.5;
  cursor: pointer;
  padding: 0 10px;
  display: inline-block;
  line-height: 23px;
}
.ta-title__extra_text{
  font-weight: bold
}
