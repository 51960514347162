.ta-slots .ta-hover-popup {
  display: inline-block;
  width: calc(12.5% - 8px);
  margin: 0 4px 8px 4px;
}

.ta-slots .ta-hover-popup.course {
  width: calc(100% - 8px);
}

.ta-slots .ta-hover-popup__content.top {
  bottom: calc(100% + 5px);
  /* filter: none; */
  /* box-shadow: #000 0 0 5px 0; */
}

.ta-slots .ta-hover-popup .ta-slots__slot {
  width: 100%;
}

.ta-slots__title {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  border: 0;
  padding: 0;
  margin: 0 0 16px 0;
}

.ta-slots__title.no-margin {
  margin: 0;
}

.ta-slots__subtitle {
  color: rgba(80, 9, 0 94, 0.7);
  font-size: 11px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  padding: 4px 0 16px 0;
  margin: 0;
}

.ta-slots__slot {
  width: calc(12.5% - 8px);
  line-height: 14px;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 3px;
  /* margin: 0 4px 8px 4px; */
  margin: 0;
  cursor: pointer;
  opacity: none;
}

.ta-slots__slot.active {
  background: #cb3b43;
}

.ta-slots__slot.discount {
  background: #f9ebd2;
  color: #505a5e;
}

.ta-slots__weekly .ta-slots__slot {
  width: calc(16.66% - 8px);
}

.ta-slots__daily .ta-slots__slot {
  padding: 8px 2px;
  white-space: nowrap;
}

.ta-other-days .ta-cta {
  text-align: left;
  margin-bottom: 8px;
}

.ta-no-slots {
  background: rgba(80, 90, 94, 0.1);
  text-align: center;
  border: 1px solid rgba(182, 186, 189, 0.5);
  padding: 16px;
  margin: 0 4px;
  border-radius: 3px;
}

.ta-no-slots__icon {
  display: block;
  font-size: 24px;
  text-align: center;
  margin-bottom: 8px;
}

.ta-no-slots__title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.ta-no-slots__text {
  line-height: 16px;
  font-size: 12px;
  font-weight: normal;
}

@media only screen and (max-width: 767px) {
  .ta-slots .ta-hover-popup {
    width: calc(20% - 8px);
  }

  .ta-slots__slot {
    width: calc(20% - 8px);
    padding-top: 10px;
    padding-bottom: 10px;
  }
}