.ta-debug-options {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
}

.ta-debug-options.active {
  visibility: visible;
  opacity: 1;
}

.ta-debug-options-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}

.ta-debug-options-form {
  position: absolute;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  width: 350px;
  background: #fff;
  border-radius: 5px;
  padding: 0 16px 16px;
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-debug-options-form form {
  padding-bottom: 0;
}

.ta-debug-options-form .ta-bordered-box {
  margin-bottom: 0;
}

.ta-debug-options-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
  color: #505a5e;
  border-radius: 0 3px 3px 0;
  margin-right: -32px;
  margin-top: 20px;
  cursor: pointer;
}

.ta-debug-options-form .ta-checkbox.switch::before {
  z-index: 9999;
}

.ta-debug-options-form .ta-btn {
  height: 35px;
  line-height: 33px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 100;
  cursor: pointer;
  outline: none;
  display: block;
  width: 100%;
  text-align: center;
}

.ta-debug-options-form .ta-btn.ta-btn-secondary {
  background: #b6babd;
  border-color: #b6babd;
  color: #505a5e;
}

.ta-debug-options-form .ta-btn.ta-btn-primary {
  background: #cb3b43;
  border-color: #cb3b43;
  color: #fff;
  font-weight: bold;
  text-align: center;
}