.ta-booking-info-mrspex {
  background: #fff;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  padding: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  z-index: 1;
  -ms-transition: top 0.5s ease;
  -webkit-transition: top 0.5s ease;
  -moz-transition: top 0.5s ease;
  transition: top 0.5s ease;
}

.ta-booking-info-mrspex.active {
  top: 48px;
}

.ta-booking-info-mrspex__title {
  font-size: 14px;
  font-weight: 500;  
}

.ta-booking-info-mrspex__additional {
  margin-top: 2px;
}

.ta-booking-info-mrspex__additional-element {
  display: inline-block;
  color: #757575;
  font-size: 12px;
  margin: 2px 16px 2px 0;
}

.ta-booking-info-mrspex__additional-icon {
  margin-right: 4px;
}

.ta-booking-info-mrspex__price {
  opacity: 0.54;
  color: #202020;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
}