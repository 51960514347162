.ta-no-results {
  text-align: center;
  background: rgba(56, 95, 135, 0.1);
  border-radius: 3px;
  padding: 15px;
}

.ta-no-results__message {
  font-size: 15px;
  font-weight: 400;
  color: #505a5e;
}

.ta-no-results__title {
  font-weight: bold;
  margin-bottom: 10px;
}

.ta-no-results__body {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
}

.ta-no-results .ta-btn {
  margin-top: 15px;
}