.ta-auth-login__login {
  height: 400px;
  display: flex;
  padding: 0 28px;
}

.ta-auth-login__inner {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}

.ta-auth-login__login .ta-row {
  margin-bottom: 14px;
}

.ta-auth-right {
  text-align: right;
}

.ta-auth-link {
  line-height: 40px;
  background: none;
  color: rgba(80, 90, 94, 0.5);
  font-size: 12px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  -ms-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.ta-auth-link:hover {
  color: #cb3b43;
}

.ta-auth-login__inner .ta-form-error {
  margin: 10px 0;
}

.ta-auth-login__inner .ta-message .ta-cta-primary {
  text-decoration: none;
  color: #fff;
  margin-top: 10px;
  margin-left: 33%;
}

.ta-auth-login__inner .ta-message .no-label p {
  text-align: center;
}

.ta-auth-login__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-auth-login__password-field input {
  padding-right: 32px;
}