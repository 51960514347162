.ta-enterprise-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transition-delay: 0.2s;
}

.ta-enterprise-loader.no-background {
  background: none;
}

.ta-enterprise-loader.active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.ta-enterprise-loader.no-background.active {
  transition: all 0s;
  transition-delay: 0s;
}

.ta-enterprise-loader .ta-enterprise-loader__box {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #000;
  color: #fff;
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}
