.ta-select.inline {
  height: auto;
}

.ta-select.inline.focused {
  transition: all 0.5s;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-select .ta-select-input {
  display: block;
  width: 100%;
  border: 1px solid rgba(80, 90, 94, 0.6);
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  padding: 0 18px 0 10px;
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
  outline: none;
  transition: all 0.5s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-select .ta-select-input::placeholder {
  height: 32px;
  line-height: 32px;
}

.ta-select .ta-select-input:disabled {
  color: #505a5e;
  background-color: #fff;
  opacity: 1;
  pointer-events: none;
}

.ta-select.hasValue .ta-select-input {
  border-color: #505a5e;
}

.ta-select.focused .ta-select-input {
  color: #385f87;
  border-color: #385f87;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-select.focused.inline .ta-select-input {
  background-color: transparent;
  box-shadow: none;
}

.ta-select.active.bottom .ta-select-input {
  border-radius: 3px 3px 0 0;
}

.ta-select.active.top .ta-select-input {
  border-radius: 0 0 3px 3px;
}

.ta-select.active.right .ta-select-input {
  border-radius: 3px;
}

.ta-select.active.left .ta-select-input {
  border-radius: 3px;
}

.ta-select .ta-select__arrow {
  cursor: pointer;
  position: absolute;
  color: #505a5e;
  font-size: 12px;
  top: 16px;
  right: 8px;
}

.ta-select .ta-select__arrow.disabled {
  cursor: default;
}

.ta-select .ta-select__options {
  position: absolute;
  box-shadow: none;
  border: 1px solid #385f87;
  background: #f6f7f9;
  padding: 5px;
  z-index: 100;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
  max-height: 160px;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.ta-select .ta-select__options.bottom {
  top: 37px;
  left: 0;
  right: 0;
  border-radius: 0 0 3px 3px;
  margin-top: 1px;
  margin-bottom: 10px;
}

.ta-select .ta-select__options.top {
  bottom: 37px;
  left: 0;
  right: 0;
  border-radius: 3px 3px 0 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

.ta-select .ta-select__options.right {
  top: calc(-80px + 50%);
  left: 100%;
  right: -100%;
  border-radius: 3px;
  margin-left: 1px;
}

.ta-select .ta-select__options.left {
  top: calc(-80px + 50%);
  left: -100%;
  right: 100%;
  border-radius: 3px;
  margin-right: 1px;
}

.ta-select .ta-select__options.pending {
  min-height: 40px;
}

.ta-select.active .ta-select__options {
  visibility: visible;
  opacity: 1;
}

.ta-select .ta-select__options__item {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 300;
  padding: 8px;
  height: 28px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  color: #505a5e;
}

.ta-select .ta-select__options__item:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-select .ta-select__options__item:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-select .ta-select__options__item:last-child {
  border-bottom: none;
}

.ta-select .ta-select__options__item.hasAvatar {
  height: 32px;
}

.ta-select .ta-select__options__item.hasAvatar .ta-avatar__image {
  height: 20px;
  width: 20px;
}

.ta-select .ta-select__options__item.hasAvatar .ta-avatar__image img {
  height: 20px;
  width: 20px;
}

.ta-select .ta-select__options__item.selected,
.ta-select .ta-select__options__item.active,
.ta-select .ta-select__options__item:hover {
  background-color: rgba(56, 95, 135, 0.1);
}

.ta-select .ta-select__options__item .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 9px;
  margin: 0;
  padding: 0;
  margin-right: 4px;
  vertical-align: middle;
}

.ta-select .ta-select__options__no-results-text {
  font-size: 11px;
  color: #969c9e;
  font-weight: 100;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
}

.ta-select .ta-select__options__category {
  border-bottom: 1px dashed #385f87;
  font-size: 13px;
  font-weight: 500;
  padding: 8px;
  height: 34px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #505a5e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-select .ta-select__options__category-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-select .ta-select__options__category-button {
  width: 71px;
  height: 20px;
  padding: 4px 8px;
  line-height: 0;
}

.ta-select .ta-select__values__category {
  margin-top: 8px;
}

.ta-select .ta-select__values__category .ta-striped-title__label {
  font-weight: bold !important;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ta-select .ta-select__values__category .ta-multi-select__values-item {
  margin-top: 0;
}

.ta-select .ta-select__options__category-extraText {
  margin-left: 5px;
  font-style: italic;
  font-weight: 400;
}

.ta-select .ta-multi-select__values-item {
  display: inline-block;
  opacity: 1;
  border-radius: 3px;
  background-color: #505a5e;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  padding-left: 8px;
  margin: 5px 5px 0 0;
  cursor: default;
}

.ta-select .ta-multi-select__values-item.hasPattern {
  background-image: url('/images/patterns/pattern-dots-light.png');
  background-repeat: repeat;
  background-size: 10px;
}

.ta-select .ta-multi-select__values-item:hover {
  opacity: 0.7;
}

.ta-select .ta-multi-select__values-item-delete {
  float: right;
  padding: 0 8px;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
}

.ta-select .ta-multi-select__values-item-label {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 205px;
  white-space: nowrap;
  height: 26px;
  line-height: 26px;
}

.ta-select .ta-multi-select__values-item-avatar {
  float: left;
  margin-top: 3px;
  margin-left: 3px;
}

.ta-select .ta-multi-select__values-item-avatar .ta-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 9px;
  margin: 0;
  padding: 0;
  margin-right: 4px;
  margin-left: -5px;
  vertical-align: middle;
}

.ta-select .ta-avatar__image {
  height: 20px;
  width: 20px;
}

.ta-select .ta-avatar__image img {
  height: 20px;
  width: 20px;
}

.ta-select .ta-multi-select__values.inline {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 302px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-delete {
  height: 20px;
  line-height: 20px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-label {
  height: 20px;
  line-height: 20px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item-avatar {
  margin-top: 2px;
}

.ta-select .ta-multi-select__values.inline .ta-avatar {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.ta-select .ta-multi-select__values.inline .ta-multi-select__values-item {
  height: 20px;
  display: flex;
  margin: 5px 0 0 5px;
}

.ta-select .ta-multi-select__values.inline .ta-select-input {
  width: 100%;
  flex: 1;
}

.ta-select .ta-multi-select__values.inline.hasValue .ta-select-input {
  margin-left: 5px;
}
