.ta-not-found {
  height: 100%;
  display: flex;
  text-align: center;
  padding: 0 28px;
}

.ta-not-found__container {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}

.ta-not-found__icon {
  color: #505a5e;
  font-size: 48px;
  margin-bottom: 12px;
}

.ta-not-found__inner .ta-popup__main_title {
  color: #505a5e;
  font-size: 16px;
  margin-bottom: 8px;
}
