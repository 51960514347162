.ta-resources {
  background: #f4f3f3;
  border-top: 1px solid #d3d6d7;
  margin: 24px 0 32px 0;
  border-radius: 0 0 3px 3px;
  position: relative;
  -ms-transition: padding 0.2s ease, margin 0.2s ease;
  -webkit-transition: padding 0.2s ease, margin 0.2s ease;
  -moz-transition: padding 0.2s ease, margin 0.2s ease;
  transition: padding 0.2s ease, margin 0.2s ease;
}

.ta-resources.selected {
  padding: 0;
  margin-bottom: 32px;
}

.ta-resources.selected.closed {
  margin-bottom: 64px;
}

.ta-resources__title {
  min-width: 228px;
  height: 24px;
  line-height: 24px;
  background: #fff;
  border: 1px solid #d3d6d7;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  padding: 0 16px;
  position: absolute;
  left: 50%;
  top: -12px;
  z-index: 2;
  border-radius: 12px;
  cursor: pointer;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -ms-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ta-resources__title.animation {
  width: 24px;
  padding: 0;
}

.ta-resources__title.selected {
  min-width: 56px;
  height: 56px;
  line-height: 56px;
  top: -28px;
  border-radius: 28px;
}

.ta-resources__title.animation .ta-resources__title__label, .ta-resources__title.selected .ta-resources__title__label {
  display: none;
}

.ta-resources__title__icon {
  font-size: 14px;
  margin-right: 4px;
}

.ta-resources__title.animation .ta-resources__title__icon, .ta-resources__title.selected .ta-resources__title__icon {
  display: none;
}

.ta-resources__title .ta-avatar {
  width: 56px;
  height: 56px;
  line-height: 56px;
  opacity: 0;
  border-radius: 56px;
  position: absolute;
  left: -1px;
  top: -1px;
  -ms-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.ta-resources__title.selected.employee .ta-avatar {
  opacity: 1;
}

.ta-resources__title .ta-avatar .ta-avatar__image, .ta-resources__title .ta-avatar .ta-avatar__image img {
  width: 56px;
  height: 56px;
  border-radius: 56px;
}

.ta-resources__title__employee {
  line-height: 16px;
  font-size: 12px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 58px;
  z-index: 2;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -ms-transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.ta-resources__title.selected.employee .ta-resources__title__employee {
  opacity: 1;
}

.ta-resources__container {
  max-height: 10000px;
  opacity: 1;
  overflow: hidden;
  visibility: visible;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ta-resources.closed .ta-resources__container {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.ta-resources__container .ta-resources__inner {
  padding: 6px;
  -ms-transition: padding 0.3s ease;
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
}

.ta-resources .ta-resources__container.no-slider .ta-resources__inner {
  padding-top: 24px;
}

.ta-resources.selected .ta-resources__container.no-slider .ta-resources__inner {
  padding-top: 54px;
}

.ta-resources__container .ta-resources__inner .ta-slide-selector {
  margin-top: 8px;
}

.ta-resources.selected .ta-resources__container .ta-resources__inner .ta-slide-selector {
  margin-top: 48px;
}

.ta-resources__item__select-all {
  width: 96px;
  height: 84px;
  display: inline-block;
  text-align: center;
  color: #505a5e;
  font-size: 10px;
  font-weight: 500;
  margin: 1px;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
}

.ta-resources__item__select-all__name {
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 64px;
  left: 5px;
  right: 5px;
}

.ta-resources__item__select-all__avatar {
  width: 48px;
  height: 48px;
  line-height: 48px;
  background-color: #e5e7e7;
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 24px;
}

.ta-resources .ta-avatar {
  z-index: 2;
}

.ta-resources__employee__selector {
  width: 20px;
  height: 20px;
  line-height: 20px;
  content: " ";
  background: #e6e6e7;
  text-align: center;
  position: absolute;
  right: -18px;
  top: 17px;
  z-index: 1;
  border-radius: 0 3px 3px 0;
}
