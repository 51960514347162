.ta-success__container {
  height: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-success__inner {
  width: 100%;
  text-align: center;
}

.ta-success__icon {
  color: #83a676;
  font-size: 48px;
  margin-bottom: 8px;
}

.ta-success_title {
  color: #83a676;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  padding: 0;
  margin: 0 0 8px 0;
}

.ta-success .ta-cta-third.ta-cta-fluid {
  min-width: 50%;
}

.ta-success .ta-cta-third i {
  font-size: 14px;
  margin-right: 6px;
}

.ta-success__footer {
  background: #505a5e;
  color: #fff;
  text-align: center;
  padding: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  border-radius: 0 0 3px 3px;
}

.ta-success__footer .ta-dotted-title {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 0;
  margin: 0 0 8px 0;
}

.ta-success__footer .ta-text-box {
  color: #fff;
}
