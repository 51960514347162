.ta-widget-overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.ta-widget {
  width: 550px;
  height: 550px;
  color: #505a5e;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}

.ta-widget.with-upselling {
  width: 870px;
}

.ta-widget * {
  outline: none;
}

.ta-widget.fullscreen {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 0);
}

.ta-widget__container {
  width: 550px;
  height: 550px;
  background: #fff;
  overflow: hidden;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px 0 5px 5px;
}

.ta-widget.fullscreen .ta-widget__container {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.ta-widget.with-upselling .ta-widget__container {
  border-radius: 5px 0 0 5px;
}

.ta-widget.fullscreen.with-upselling .ta-widget__container {
  width: calc(100% - 320px);
}

.ta-widget.ta-widget__no-close .ta-widget__container {
  border-radius: 5px;
}

.ta-widget.fullscreen.ta-widget__no-close .ta-widget__container {
  border-radius: 0;
}

/* disable mobile browser zoom on input focus */
.ta-widget.type-mobile input:focus,
.ta-widget.type-mobile select:focus,
.ta-widget.type-mobile textarea:focus {
  font-size: 16px !important;
  touch-action: none;
}

.ta-widget-blur .ta-widget__container::after {
  background: rgba(255, 255, 255, 0.8);
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.ta-widget__close_button {
  width: 48px;
  height: 48px;
  line-height: 49px;
  background: #8f9698;
  color: #fff;
  font-size: 24px;
  text-align: center;
  position: absolute;
  right: -48px;
  top: 0;
  z-index: 1;
  border-radius: 0 3px 3px 0;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
  -ms-transition: right 0.3s ease;
  -webkit-transition: right 0.3s ease;
  -moz-transition: right 0.3s ease;
  transition: right 0.3s ease;
  cursor: pointer;
}

.ta-widget.fullscreen .ta-widget__close_button {
  right: 0;
  z-index: 3;
}

.ta-widget__close_button:hover {
  background: #cb3b43;
}

.ta-widget__no-close .ta-widget__close_button {
  right: 0;
}

.ta-widget.fullscreen.ta-widget__no-close .ta-widget__close_button {
  display: none;
}

/* STEPS */

.ta-widget__steps {
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 48px;
  bottom: 5px;
  z-index: 1;
  -ms-transition: top 0.5s ease;
  -webkit-transition: top 0.5s ease;
  -moz-transition: top 0.5s ease;
  transition: top 0.5s ease;
}

.ta-widget__steps.short-service {
  top: 86px;
}

.ta-widget-blur .ta-widget__steps {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.ta-step {
  width: 100%;
  overflow: auto;
  opacity: 0;
  padding: 16px 16px 16px 16px;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  -ms-transition: left 0.5s ease, opacity 0.5s ease 0.2s;
  -webkit-transition: left 0.5s ease, opacity 0.5s ease 0.2s;
  -moz-transition: left 0.5s ease, opacity 0.5s ease 0.2s;
  transition: left 0.5s ease, opacity 0.5s ease 0.2s;
  -webkit-overflow-scrolling: touch;
}

.ta-step.active {
  opacity: 1;
  left: 0;
}

.ta-step.prev {
  opacity: 0;
  left: -100%;
}

.ta-step.has-supportedby {
  bottom: 40px;
}

.ta-step.has-poweredby {
  bottom: 30px;
}

.ta-step.has-button, .ta-step.has-supportedby.has-button {
  bottom: 50px;
}

/* FORM */

.ta-form {
  padding-bottom: 0;
}

/* POPUP */

.ta-popup {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  min-height: 300px;
  background: #fff;
  position: absolute;
  left: 4px;
  top: 100%;
  right: 4px;
  z-index: 100;
  border-radius: 3px;
  -ms-transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  -moz-transition: top 0.3s ease;
  transition: top 0.3s ease;
}

.ta-popup.active {
  top: 4px;
}

.ta-popup-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: none;
  color: #505a5e;
  font-size: 24px;
  text-align: center;
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
  -ms-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.ta-popup-close:hover {
  color: #cb3b43;
}

.ta-popup-back {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: none;
  color: #505a5e;
  font-size: 24px;
  text-align: center;
  border: 0;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
  -ms-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.ta-popup-back:hover {
  color: #cb3b43;
}

.ta-popup__main_title {
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 0;
  margin: 0 8px 8px 8px;
  padding: 0;
}

.ta-popup__secondary_title {
  line-height: 16px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  border: 0;
  margin: 0 0 16px 0;
  padding: 0;
}

/* BUTTONS */

.ta-cta-primary {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #cb3b43;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.ta-cta-primary:hover {
  background: #ac3239;
}

.ta-cta-secondary {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #505a5e;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.ta-cta-secondary:hover {
  background: #444c4f;
}

.ta-cta-third {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #b6babd;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.ta-cta-third:hover {
  background: #9b9ea1;
}

.ta-cta-fourth {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #505a5e;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.ta-cta-fourth:hover {
  background: #444c4f;
}

.ta-cta-ghost {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #fff;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #b6babd;
  padding: 11px 15px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

.ta-cta-ghost:hover {
  background: #444c4f;
  border-color: #444c4f;
  color: #fff;
}

.ta-cta-blue {
  width: 100%;
  line-height: 16px;
  display: block;
  background: #385f87;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: 0;
  padding: 12px 16px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.ta-cta-blue:hover {
  background: #385f87;
}

.ta-cta-primary.ta-cta-middle, .ta-cta-secondary.ta-cta-middle, .ta-cta-third.ta-cta-middle, .ta-cta-fourth.ta-cta-middle, .ta-cta-ghost.ta-cta-middle, .ta-cta-blue.ta-cta-middle {
  font-size: 12px;
  padding: 10px 15px;
}

.ta-cta-primary.ta-cta-fluid, .ta-cta-secondary.ta-cta-fluid, .ta-cta-third.ta-cta-fluid, .ta-cta-fourth.ta-cta-fluid, .ta-cta-ghost.ta-cta-fluid, .ta-cta-blue.ta-cta-fluid {
  width: auto;
  display: inline-block;
}

.ta-cta-primary.ta-cta-two-lines, .ta-cta-secondary.ta-cta-two-lines, .ta-cta-third.ta-cta-two-lines, .ta-cta-fourth.ta-cta-two-lines, .ta-cta-blue.ta-cta-two-lines {
  line-height: 14px;
  font-weight: 300;
  padding-top: 6px;
  padding-bottom: 6px;
}

.ta-cta-two-lines strong {
  line-height: 14px;
  display: block;
  font-size: 12px;
  font-weight: bold;
}

.ta-cta-primary.disabled, .ta-cta-secondary.disabled, .ta-cta-third.disabled, .ta-cta-fourth.disabled, .ta-cta-ghost.disabled, .ta-cta-blue.disabled {
  cursor: not-allowed;
  background: #b6babd;
  color: #505a5e;
}

/* COMMON > ACCORDION */

.ta-widget .ta-accordion .ta-accordion__body {
  overflow: hidden;
}

.ta-widget .ta-accordion.expand .ta-accordion__body {
  overflow: visible;
}

/* COMMON > LIST > SIMPLELIST */

.ta-widget .ta-simple-list-item {
  min-height: 53px;
  background: #f4f3f3;
  padding: 8px;
  margin-bottom: 0;
  -ms-transition: background 0.2s ease, border-color 0.2s ease;
  -webkit-transition: background 0.2s ease, border-color 0.2s ease;
  -moz-transition: background 0.2s ease, border-color 0.2s ease;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.ta-widget .ta-simple-list-item.ta-simple-list-item__hasdescription {
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.ta-widget .ta-services__service:hover .ta-simple-list-item, .ta-widget .ta-services__service:hover .ta-simple-list-item__description, .ta-widget .ta-simple-list-item:hover {
  background: #f6f8fa;
  border-color: #505a5e;
  cursor: pointer;
}

.ta-widget .ta-services__service:hover .ta-simple-list-item__description {
  border-top-color: #fff;
}

.ta-widget .ta-simple-list-item__title {
  font-weight: 500;
  margin-bottom: 0;
}

.ta-widget .ta-simple-list-item__content {
  line-height: 16px;
  opacity: 1;
  max-width: calc(100% - 25px);
}

.ta-widget .ta-simple-list-item__content i {
  font-size: 12px;
  margin-right: 5px;
}

.ta-widget .ta-simple-list-item__buttons {
  top: 3px;
  right: 4px;
  transform: translateY(0);
  height: calc(100% - 3px);
}

.ta-widget .ta-simple-list__inline li {
  display: inline-block;
  padding-right: 10px;
}

.ta-widget .ta-simple-list-item__body {
  max-height: 10000px;
  padding-top: 16px;
  cursor: default;
  -ms-transition: max-height 0.3s ease, padding 0.3s ease;
  -webkit-transition: max-height 0.3s ease, padding 0.3s ease;
  -moz-transition: max-height 0.3s ease, padding 0.3s ease;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.ta-widget .ta-simple-list-item__body.closed {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
}

.ta-widget .ta-simple-list-item__description {
  line-height: 16px;
  background: #f4f3f3;
  color: #505a5e;
  font-size: 12px;
  font-weight: 300;
  border-top: 2px solid #fff;
  padding: 10px;
  margin: -8px;
  margin-top: 8px;
  border-radius: 0 0 3px 3px;
  -ms-transition: background 0.2s ease, border-color 0.2s ease;
  -webkit-transition: background 0.2s ease, border-color 0.2s ease;
  -moz-transition: background 0.2s ease, border-color 0.2s ease;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.ta-widget .ta-simple-list-item__offer {
  line-height: 12px;
  background: #f9ebd2;
  color: #505a5e;
  font-size: 12px;
  font-weight: 300;
  border-top: 2px solid #fff;
  padding: 8px 10px;
  margin: -8px;
  margin-top: 8px;
  border-radius: 0 0 3px 3px;
  -ms-transition: background 0.2s ease, border-color 0.2s ease;
  -webkit-transition: background 0.2s ease, border-color 0.2s ease;
  -moz-transition: background 0.2s ease, border-color 0.2s ease;
  transition: background 0.2s ease, border-color 0.2s ease;
}

.ta-widget .ta-simple-list-item__offer .fas {
  margin-right: 4px;
}

.ta-text-box {
  line-height: 16px;
  color: #777;
  font-size: 12px;
  font-weight: normal;
  margin: 0 8px 16px 8px;
}

.ta-text-box.center {
  text-align: center;
}

/* GRID */

.ta-row {
  display: table;
  width: calc(100% + 8px);
  margin: 0 -4px;
  margin-bottom: 8px;
}

.ta-row.no-offset {
  margin-bottom: 0;
}

.ta-col {
  display: table-cell;
  padding: 0 4px;
  vertical-align: top;
}

.ta-col.col-10 {
  width: 10%;
}

.ta-col.col-15 {
  width: 15%;
}

.ta-col.col-20 {
  width: 20%;
}

.ta-col.col-25 {
  width: 25%;
}

.ta-col.col-30 {
  width: 30%;
}

.ta-col.col-33 {
  width: 33.33%;
}

.ta-col.col-35 {
  width: 35%;
}

.ta-col.col-40 {
  width: 40%;
}

.ta-col.col-45 {
  width: 45%;
}

.ta-col.col-50 {
  width: 50%;
}

.ta-col.col-55 {
  width: 55%;
}

.ta-col.col-60 {
  width: 60%;
}

.ta-col.col-65 {
  width: 65%;
}

.ta-col.col-70 {
  width: 70%;
}

.ta-col.col-75 {
  width: 75%;
}

.ta-col.col-80 {
  width: 80%;
}

.ta-col.col-85 {
  width: 85%;
}

.ta-col.col-90 {
  width: 90%;
}

.ta-col.col-95 {
  width: 95%;
}

.ta-col.col-100 {
  width: 100%;
}

/* FLOAT */

.relative {
  position: relative;
}

/* COMMON > TITLE > TITLE */

.ta-widget .ta-title {
  font-size: 16px;
}

/* COMMON > TITLE > SECTIONTITLE */

.ta-widget .ta-section-title {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #d3d6d7;
  padding: 0 0 2px 0;
  margin: 8px 0 4px 0;
}

.ta-widget .ta-section-title .ta-section-title__label {
  height: auto;
  line-height: inherit;
}

/* COMMON > TITLE > SMALLTITLE */

.ta-small-title__simple {
  line-height: 14px;
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}

/* COMMON > FORM > ALL DEFINITIONS */

.ta-widget .ta-checkbox:after {
  background: #fff;
}

.ta-widget .ta-radio:after {
  background: #fff;
}

.ta-widget__form__element {
  margin-top: 7px;
}

/* REACT ADD TO CALENDAR */

.react-add-to-calendar {
  position: relative;
  display: flex;
  justify-content: center;
}

.react-add-to-calendar__button {
  width: 252px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  background: #b6babd;
  color: #505a5e;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  border: 0;
  cursor: pointer;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.react-add-to-calendar__button:hover {
  background: #9b9ea1;
}

.react-add-to-calendar__button i {
  font-size: 14px;
  margin-right: 2px;
}

.react-add-to-calendar__dropdown {
  width: 252px;
  background: #eceff3;
  border: 1px solid #385f87;
  padding: 2px;
  border-radius: 3px;
  position: absolute;
  bottom: 42px;
  z-index: 2;
}

.react-add-to-calendar__dropdown li {
  border-bottom: 1px dashed #505a5e;
  padding: 1px 0;
}

.react-add-to-calendar__dropdown li:last-child {
  border-bottom: 0;
}

.react-add-to-calendar__dropdown a {
  display: block;
  color: rgba(80, 90, 94, 0.6);
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  padding: 6px 4px;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
}

.react-add-to-calendar__dropdown a:hover {
  background: rgba(56, 95, 135, 0.1);
  color: #385f87;
}

.ta-btn {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 100;
  cursor: pointer;
  outline: none;
}

.ta-btn-icon-only {
  width: 35px;
  padding: 0;
  text-align: center;
}

.ta-widget-overlay-disabled {
  display: none;
}

.ta-widget-disabled {
  z-index: 5;
}

@media only screen and (max-width: 990px) {
  .ta-widget.with-upselling {
    width: 550px;
  }
  .ta-widget.with-upselling.fullscreen {
    width: 100%;
  }
  .ta-widget.fullscreen.with-upselling .ta-widget__container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .ta-widget {
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0, 0);
  }
  .ta-widget.with-upselling {
    width: 100%;
  }
  .ta-widget__container {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .ta-widget.ta-widget__open-languages .ta-widget__container {
    z-index: 3;
  }
  .ta-widget .ta-widget__close_button {
    right: 0;
    z-index: 3;
  }
  .ta-widget.ta-widget__no-close .ta-widget__close_button {
    display: none;
  }
}
