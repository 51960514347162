.ta-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 11;
  border-radius: 3px 0 0 0;
  -ms-transition: border-radius 0.3s ease;
  -webkit-transition: border-radius 0.3s ease;
  -moz-transition: border-radius 0.3s ease;
  transition: border-radius 0.3s ease;
}

.ta-widget__no-close .ta-header {
  border-radius: 3px 3px 0 0;
}

.ta-widget-blur .ta-header {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.ta-header__container {
  height: 48px;
  line-height: 48px;
  background: #505a5e;
  color: #fff;
  position: relative;
  z-index: 2;
  padding: 0 16px;
  border-radius: 3px 0 0 0;
  -ms-transition: border-radius 0.3s ease;
  -webkit-transition: border-radius 0.3s ease;
  -moz-transition: border-radius 0.3s ease;
  transition: border-radius 0.3s ease;
}

.ta-widget.fullscreen .ta-header__container {
  padding-right: 58px;
  border-radius: 0;
}

.ta-widget__no-close .ta-header__container {
  border-radius: 3px 3px 0 0;
}

.ta-widget.fullscreen.ta-widget__no-close .ta-header__container {
  padding-right: 16px;
}

.ta-header__container::after {
  display: block;
  content: ' ';
  clear: both;
}

.ta-header__container .ta-language-selector {
  margin: 8px 0 0 8px;
  float: right;
}

.ta-header__title {
  font-size: 18px;
  font-weight: 300;
  -ms-transition: padding 0.5s ease;
  -webkit-transition: padding 0.5s ease;
  -moz-transition: padding 0.5s ease;
  transition: padding 0.5s ease;
  float: left;
  width: calc(100% - 60px);
}

.ta-header__title-default {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ta-header__title-company {
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* BACK BUTTON */

.with-back-button .ta-header__title {
  padding-left: 32px;
}

.ta-header__back_button {
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: -50px;
  top: 12px;
  z-index: 1;
  border-radius: 3px;
  -ms-transition: background 0.2s ease, color 0.2s ease, left 0.5s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease, left 0.5s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease, left 0.5s ease;
  transition: background 0.2s ease, color 0.2s ease, left 0.5s ease;
  cursor: pointer;
}

.with-back-button .ta-header__back_button {
  left: 16px;
}

.ta-header__back_button:hover {
  background: rgba(255, 255, 255, 0.16);
}

@media only screen and (max-width: 767px) {
  .ta-widget .ta-header__container {
    padding-right: 58px;
    border-radius: 0;
  }
  .ta-widget.ta-widget__no-close .ta-header__container {
    padding-right: 16px;
  }
}
