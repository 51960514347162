.ta-courses__course .ta-simple-list-item {
  padding-left: 54px;
}


.ta-courses__course.discount .ta-simple-list-item {
  background: #f9ebd2;
  color: #505a5e;
}

.ta-courses__course .ta-avatar {
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 36px;
  font-size: 9px;
  position: absolute;
  left: 8px;
  top: 8px;
}

.ta-courses__course .ta-avatar .ta-avatar__image,
.ta-courses__course .ta-avatar .ta-avatar__image img {
  width: 100%;
  height: 100%;
}

.ta-courses__course .ta-simple-list-item__buttons {
  width: 24px;
  height: calc(100% - 6px);
}

.ta-courses__course .ta-courses__button {
  width: 24px;
  height: 100%;
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 0;
}

.ta-courses__course .ta-dotted-timeline {
  padding-bottom: 2px;
}

.ta-courses__course .ta-dotted-timeline__item {
  padding-top: 1px;
  padding-bottom: 6px;
  padding-left: 16px;
}

.ta-courses__course .ta-dotted-timeline__item.big {
  font-size: 14px;
}

.ta-courses__course .ta-dotted-timeline__item.small {
  color: #505a5e;
  font-size: 12px;
  font-weight: normal;
}

.ta-courses__course .ta-dotted-timeline__item.big:after, .ta-courses__course .ta-dotted-timeline__item.small:after {
  border-left: 1px solid #505a5e;
  left: 4px;
}

.ta-courses__course .ta-dotted-timeline__item.big:before {
  width: 7px;
  height: 7px;
  left: 1px;
  border-radius: 7px;
}

.ta-courses__course .ta-dotted-timeline__item.small:before {
  width: 5px;
  height: 5px;
  background: #505a5e;
  left: 2px;
}

.ta-courses__course.booked-out {
  opacity: 0.5;
  pointer-events: none;
}

.ta-slots__week .ta-course-info__item {
  width: 305px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}