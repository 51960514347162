.ta-locations__title {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 24px;
}

.ta-locations .ta-simple-list-item {
  margin-bottom: 4px;
}

.ta-locations-container {
  margin: 0 -8px;
}

.ta-locations-container.ta-locations-full-height {
  height: 100%;
}

.ta-locations-container .ta-col {
  padding: 0 8px;
}

.ta-locations-display-block {
  display: block;
}

.ta-locations-map {
  height: 100%;
  position: relative;
}

.ta-locations-map .ta-locations__auto-complete {
  width: calc(100% - 32px);
  height: 32px;
  position: absolute;
  left: 16px;
  bottom: 8px;
  z-index: 1000;
}

.ta-locations-map .location-search-input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  border: 1px solid rgba(80, 90, 94, 0.6);
  padding: 0 8px;
  border-radius: 3px;
}

.ta-locations-map .location-search-input.ta-locations__place-search_selected {
  border-color: #505a5e;
}

.ta-locations-map .locations-place-clear-btn {
  width: 24px;
  height: 28px;
  padding: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 2;
  border-radius: 0 3px 3px 0;
}

.ta-locations-map .locations-search-icon {
  color: rgba(80, 90, 94, 0.6);
  position: absolute;
  right: 9px;
  bottom: 9px;
  z-index: 1001;
}

.ta-locations-map .autocomplete-dropdown-container {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 34px;
  z-index: 1002;
  border-radius: 3px;
}

.ta-locations-map .autocomplete-dropdown-container .suggestion-item,
.ta-locations-map .autocomplete-dropdown-container .suggestion-item--active {
  display: block;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  padding: 6px;
  border-bottom: 1px dashed rgba(80, 90, 94, 0.6);
  border-left: 1px solid rgba(80, 90, 94, 0.6);
  border-right: 1px solid rgba(80, 90, 94, 0.6);
}

.ta-locations-map .autocomplete-dropdown-container .suggestion-item:first-child,
.ta-locations-map .autocomplete-dropdown-container .suggestion-item--active:first-child {
  border-top: 1px solid rgba(80, 90, 94, 0.6);
  border-radius: 3px 3px 0 0;
}

.ta-locations-map .autocomplete-dropdown-container .suggestion-item:last-child,
.ta-locations-map .autocomplete-dropdown-container .suggestion-item--active:last-child {
  border-bottom: 1px solid rgba(80, 90, 94, 0.6);
  border-radius: 0 0 3px 3px;
}

.ta-locations-list {
  position: relative;
}

.ta-locations-list .locations-box {
  height: calc(100% + 16px);
  overflow: auto;
  padding-right: 16px;
  position: absolute;
  left: 8px;
  top: -16px;
  right: -16px;
}

.ta-widget .ta-simple-list__inline li.ta-simple-list__inline__info {
  display: block;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #505a5e;
  margin-bottom: 8px;
  margin-top: 4px;
}

.ta-locations__box-container {
  height: 100%;
}
