.ta-dotted-timeline {}

.ta-dotted-timeline__item {
  position: relative;
  padding: 5px 0;
  padding-left: 18px;
  ;
  color: #505a5e;
}

.ta-dotted-timeline__item.big {
  font-size: 12px
}

.ta-dotted-timeline__item.big:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #505a5e;
  background: #505a5e;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 5;
}

.ta-dotted-timeline__item.big:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: -5px;
}

.ta-dotted-timeline .ta-dotted-timeline__item.big:last-child:after {
  display: none;
}

.ta-dotted-timeline__item.small {
  font-size: 10px;
  font-weight: 300;
  min-height: 18px;
}

.ta-dotted-timeline__item.small:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #b6babd;
  position: absolute;
  top: 5px;
  left: 2px;
  z-index: 5;
}

.ta-dotted-timeline__item.small:after {
  content: "";
  display: block;
  border-left: 2px dotted #b6babd;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: -5px;
}

.ta-dotted-timeline .ta-dotted-timeline__item.small:last-child:after {
  display: none;
}
