.ta-booking-info {
  background: #e6e6e7;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  padding: 8px 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  z-index: 1;
  -ms-transition: top 0.5s ease;
  -webkit-transition: top 0.5s ease;
  -moz-transition: top 0.5s ease;
  transition: top 0.5s ease;
}

.ta-booking-info.active {
  top: 48px;
}

.ta-booking-info.with-card {
  padding: 16px;
}

.ta-booking-info__title {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 4px;
}

.ta-booking-info__title.border {
  border-top: 1px solid #d4d6d7;
}

.ta-booking-info__online-payment-fields {
  margin-top: 8px;
  margin-bottom: -8px;
}

.ta-booking-info__value {
  text-align: right;
}

.ta-booking-info__value span {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #b6babd;
  padding: 4px 8px;
  border-radius: 12px;
}

.ta-booking-info__customfields__container {
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}

.ta-booking-info__customfields {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
}

.ta-booking-info__customfields__row {
  font-size: 12px;
  border-bottom: 1px solid #d4d6d7;
  padding: 8px 0;
  margin: 0;
}

.ta-booking-info__customfields__row:last-child {
  border-bottom: 0;
}

.ta-booking-info__customfields__row .fas {
  font-size: 10px;
  margin: 0 4px 0 0;
}

.ta-booking-info__customfields__title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}

.ta-booking-info .ta-confirm__row:last-child {
  border-bottom: 0;
}

.ta-booking-info .ta-confirm__row {
  padding: 8px 0 0 0;
  margin-top: 8px;
}

.ta-booking-info .ta-confirm__icon__price-calculated {
  padding-left: 4px;
}

.ta-widget .ta-booking-info .ta-simple-list-item {
  background: #fff;
  margin-bottom: 0;
}

.ta-widget .ta-booking-info .ta-simple-list-item .ta-simple-list-item__body {
  padding-top: 8px;
}

.ta-widget .ta-booking-info .ta-simple-list-item .ta-simple-list-item__body.closed {
  padding-top: 0;
}

.ta-booking-info__online-payment__countdown {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: -8px;
  text-align: center;
}

.ta-booking-info__online-payment__countdown * {
  display: inline-block;
}

.ta-booking-info__transaction-fee-marker {
  font-style: italic;
  font-size: 12px;
  color: #505a5e;
  margin: 0 5px;
}
