.ta-date-input .react-datepicker-wrapper {
  display: block;
}

.ta-date-input .react-datepicker__input-container {
  display: block;
}

.ta-date-input .react-datepicker-popper {
  z-index: 10;
}

.ta-date-input.has-times .react-datepicker-popper {
  width: 341px;
}

.ta-date-input.has-times .react-datepicker {
  width: 100%;
}

.ta-date-input .react-datepicker__time-container {
  border-left: 1px solid #385f87;
  float: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90px;
  overflow: hidden;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time {
  background: #eceff3;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding: 0;
  margin: 3px 0;
  text-align: center;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  background: rgb(235, 239, 243);
  border-bottom: 1px dashed #385f87;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0 5px;
  width: calc(100% - 10px);
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:last-child {
  border-bottom: none;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected, .ta-date-input .react-datepicker__time-list-item .react-datepicker__time-list-item--selected {
  background: #375f88;
  color: #fff;
  border-radius: 3px;
}

.ta-date-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
}

.ta-date-input .react-datepicker__current-month, .ta-date-input .react-datepicker-time__header, .ta-date-input .react-datepicker__header.react-datepicker__header--time {
  height: 34px;
  line-height: 34px;
  background: #385f87;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  border-radius: 0;
}

.ta-date-input .react-datepicker {
  font-family: 'Ubuntu', sans-serif;
  border: 1px solid #385f87;
  border-radius: 3px;
  color: #505a5e;
  background: #eceff3;
  font-size: 12px;
  font-weight: 300;
}

.ta-date-input .react-datepicker__header {
  border-bottom: none;
  background-color: #eceff3;
  padding-top: 0;
}

.ta-date-input .react-datepicker__month {
  margin: 0 5px 5px 5px;
}

.ta-date-input .react-datepicker__day-names {
  margin: 5px 5px 0 5px;
}

.ta-date-input .react-datepicker__current-month {
  height: 34px;
  line-height: 34px;
  background: #385f87;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.ta-date-input .react-datepicker__navigation {
  top: 0;
  width: 34px;
  height: 34px;
  border: none;
  text-indent: 0;
  font-size: 0;
}

.ta-date-input .react-datepicker__navigation:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
}

.ta-date-input .react-datepicker__navigation--previous {
  left: 0;
}

.ta-date-input .react-datepicker__navigation--previous:before {
  content: "\f104"
}

.ta-date-input .react-datepicker__navigation--next {
  right: 0;
}

.ta-date-input .react-datepicker__navigation--next:before {
  content: "\f105"
}

.ta-date-input.has-times .react-datepicker__navigation--next {
  right: 91px;
}

.ta-date-input .react-datepicker__day, .ta-date-input .react-datepicker__day-name, .ta-date-input .react-datepicker__time-name {
  color: #505a5e;
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin: 2px 4px;
  border-radius: 30px;
}

.ta-date-input .react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 0.5;
}

.ta-date-input .react-datepicker__day:hover {
  background: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-date-input .react-datepicker__day-names .react-datepicker__day-name {
  font-size: 10px;
  color: #969c9e;
  text-transform: uppercase;
  font-weight: 500;
}

.ta-date-input .react-datepicker__day--today {
  box-shadow: inset #375f88 0 0 0 1px;
  color: #505a5e;
  font-weight: 300;
}

.ta-date-input .react-datepicker__day--keyboard-selected, .ta-date-input .react-datepicker__day--selected, .ta-date-input .react-datepicker__day--in-selecting-range, .ta-date-input .react-datepicker__day--in-range {
  border-radius: 30px;
  background: #375f88;
  color: #fff;
}

.ta-date-input .react-datepicker__triangle {
  left: 50%;
  transform: translateX(-50%);
}

.ta-date-input .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #375f88;
  margin-left: 0;
}

.ta-date-input .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  display: none;
}

.ta-date-input .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  border-top-color: #375f88;
  margin-left: 0;
}

.ta-date-input .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #eceff3;
  bottom: 1px;
}

.ta-date-input .react-datepicker__header__dropdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  font-weight: 500;
  z-index: 100;
  text-align: left;
  background: #385f87;
  color: #fff;
  display: none;
}

.ta-date-input .react-datepicker__header__dropdown:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 50%;
  border-right: 1px solid #fff;
  opacity: .2;
}

.ta-date-input.has-dropdown .react-datepicker__header__dropdown {
  display: block;
}

.ta-date-input .react-datepicker__year-dropdown-container--select, .ta-date-input .react-datepicker__month-dropdown-container--select, .ta-date-input .react-datepicker__year-dropdown-container--scroll, .ta-date-input .react-datepicker__month-dropdown-container--scroll {
  width: 50%;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 10;
}

.ta-date-input .react-datepicker__year-read-view--down-arrow, .ta-date-input .react-datepicker__month-read-view--down-arrow {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  border: none;
  height: 34px;
  margin: 0;
  padding: 0;
}

.ta-date-input .react-datepicker__year-read-view--down-arrow:before, .ta-date-input .react-datepicker__month-read-view--down-arrow:before, .ta-date-input .react-datepicker__year-dropdown-container--select:before, .ta-date-input .react-datepicker__month-dropdown-container--select:before {
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: -4px;
  left: -10px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  border: none;
  width: 0;
}
.ta-date-input .react-datepicker__year-dropdown-container--select:before, .ta-date-input .react-datepicker__month-dropdown-container--select:before {
  left: auto;
  right: 16px;
}

.ta-date-input .react-datepicker__year-read-view--down-arrow:after, .ta-date-input .react-datepicker__month-read-view--down-arrow:after, .ta-date-input .react-datepicker__year-dropdown-container--select:after, .ta-date-input .react-datepicker__month-dropdown-container--select:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 4px;
  left: -10px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
  border: none;
  display: block;
  width: 0;
}
.ta-date-input .react-datepicker__year-dropdown-container--select:after, .ta-date-input .react-datepicker__month-dropdown-container--select:after {
  left: auto;
  right: 16px;
}

.ta-date-input .react-datepicker__year-read-view, .ta-date-input .react-datepicker__month-read-view {
  border: none;
  visibility: visible!important;
}

.ta-date-input .react-datepicker__month-read-view:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 0;
  border-right: 1px solid #fff;
  opacity: 0.2;
}

.ta-date-input .react-datepicker__year-dropdown, .ta-date-input .react-datepicker__month-dropdown {
  width: calc(100% + 2px);
  max-height: 192px;
  overflow: auto;
  left: -1px;
  top: calc(100% - 1px);
  border-radius: 0 0 3px 3px;
  border: 1px solid #385f87;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-date-input .react-datepicker__year-option, .ta-date-input .react-datepicker__month-option {
  background: rgb(235, 239, 243);
  text-align: left;
  border-bottom: 1px dashed #385f87;
  cursor: pointer;
  position: relative;
  padding: 0 5px;
  margin: 0 5px;
  width: calc(100% - 10px);
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
}

.ta-date-input .react-datepicker__year-option:hover, .ta-date-input .react-datepicker__month-option:hover {
  background-color: rgba(56, 95, 135, 0.1);
  color: #505a5e;
}

.ta-date-input .react-datepicker__year-option:before, .ta-date-input .react-datepicker__month-option:before {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ta-date-input .react-datepicker__year-option:after, .ta-date-input .react-datepicker__month-option:after {
  content: "";
  border-top: 1px solid rgb(235, 239, 243);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ta-date-input .react-datepicker__year-option:last-child, .ta-date-input .react-datepicker__month-option:last-child {
  border-bottom: none;
  margin-bottom: 5px;
  border-radius: 0 0 2px 2px;
}

.ta-date-input .react-datepicker__year-option:first-child, .ta-date-input .react-datepicker__month-option:first-child {
  margin-top: 5px;
  border-radius: 2px 2px 0 0;
}

.ta-date-input .react-datepicker__year-dropdown--scrollable, .ta-date-input .react-datepicker__month-dropdown--scrollable {
  height: 300px;
}

.ta-date-input .react-datepicker__year-select, .ta-date-input .react-datepicker__month-select {
  width: 100%;
  height: 34px;
  background: none;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.ta-date-input .react-datepicker__year-select::-ms-expand, .ta-date-input .react-datepicker__month-select::-ms-expand {
  display: none;
}

.ta-date-input .react-datepicker__year-select:focus::-ms-value, .ta-date-input .react-datepicker__month-select:focus::-ms-value {
  background: none;
}

.ta-date-input .react-datepicker__year-select option, .ta-date-input .react-datepicker__month-select option {
  color: #505a5e;
}

.ta-date-input .react-datepicker__navigation.react-datepicker__navigation--months, .ta-date-input .react-datepicker__navigation.react-datepicker__navigation--years {
  height: 30px;
  line-height: 28px;
  color: #505a5e;
}

.ta-date-input .react-datepicker__navigation.react-datepicker__navigation--years-upcoming:before {
  content: "\f106";
  color: #505a5e;
}

.ta-date-input .react-datepicker__navigation.react-datepicker__navigation--years-previous:before {
  content: "\f107";
  color: #505a5e;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected {
  display: none;
}

.ta-date-input .ta-form-control__icon {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
}
