.ta-form {
  padding-bottom: 30px;
}

.ta-form-error {
  background: rgba(203, 59, 67, 0.1);
  color: #cb3b43;
  padding: 10px;
  padding-left: 32px;
  margin-top: 5px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 100;
  min-height: 32px;
  position: relative;
  display: none;
}

.ta-form-error.active {
  display: block;
}

.ta-form-error.multi-fields {
  margin-top: -5px;
  margin-bottom: 10px;
}

.ta-form-error:before {
  content: "\f071";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ta-form-section {
  margin-bottom: 16px;
}

.ta-form-group {
  position: relative;
  padding-top: 5px;
}

.ta-form-group label {
  max-width: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  top: 14px;
  left: 6px;
  font-size: 13px;
  font-weight: 300;
  color: #969c9e;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

.ta-form-group label:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  right: 0;
  border-top: 3px solid #fff;
  z-index: -1;
  transition: border-color .2s;
}

.ta-form-group.disabled {
  opacity: 0.5;
}

.ta-form-group.disabled label:after {
  border-color: #e6e6e7;
}

.ta-form-control {
  display: block;
  width: 100%;
  border: 1px solid rgba(80, 90, 94, 0.6);
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 300;
  color: #505a5e;
  outline: none;
  transition: all 0.5s;
  -webkit-appearance: none;
}

textarea.ta-form-control {
  min-height: 100px;
  line-height: 18px;
  padding: 6px 10px;
  resize: vertical;
  transition-property: border-color, background-color, font-size;
}

input.ta-form-control {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input.ta-form-control::placeholder {
  height: 32px;
  line-height: 32px;
}

input.ta-form-control.has-addon {
  padding-right: 45px;
}

.ta-form-control__addon {
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 10px;
  font-weight: 500;
  color: #505a5e;
  opacity: 0.5;
  transition: top .2s ease;
}

.ta-form-control__addon.move-bottom {
  top: 40px;
}

.ta-form-group.filled .ta-form-control__addon {
  opacity: 1;
}

.ta-form-control__hint {
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #505a5e;
  opacity: 0.5;
  margin-top: 6px;
}

.ta-form-control__hint.short {
  width: 90%;
}

.ta-form-buttons>* {
  margin-right: 8px;
}

.ta-form-buttons>*:last-child {
  margin-right: 0;
}

.ta-form-group.filled label {
  visibility: visible;
  opacity: 1;
  color: #505a5e;
  top: 0;
  left: 10px;
  font-size: 11px;
}

.ta-form-group.filled .ta-form-control {
  border-color: #505a5e;
}

.ta-form-group.focused label {
  visibility: visible;
  opacity: 1;
  color: #385f87;
  top: 0;
  left: 10px;
  font-size: 11px;
}

.ta-form-group.focused label:after {
  border-color: rgb(235, 239, 243);
}

.ta-form-group.focused .ta-form-control {
  color: #385f87;
  border-color: #385f87;
  background-color: rgb(235, 239, 243);
  box-shadow: 0 5px 10px 0 rgba(56, 95, 135, 0.15);
}

.ta-form-group.disabled label {
  color: #969c9e;
}

.ta-form-group.disabled .ta-form-control {
  background-color: #e6e6e7;
  border-color: rgba(80, 90, 94, 0.6);
}
