.ta-confirm-participants {
  padding: 0 12px;
}

.ta-confirm-participants__container {
  height: calc(100% - 48px);
  display: flex;
}

.ta-confirm-participants__inner {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}