.ta-slots__week {
  min-height: 30px;
  background: #f4f3f3;
  text-align: center;
  padding: 8px 8px 1px 104px;
  margin-bottom: 4px;
  border-radius: 3px;
  position: relative;
}

.ta-slots__week.booked-out {
  opacity: 0.5;
}

.ta-slots__week .ta-slots__week__label {
  width: 96px;
  background: rgba(80, 90, 94, 0.1);
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 3px 0 0 3px;
}

.ta-slots__week__slots {
  max-height: 10000px;
  text-align: left;
  margin: 0 -4px;
  -ms-transition: max-height 0.3s ease;
  -webkit-transition: max-height 0.3s ease;
  -moz-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
}

.ta-slots__week .ta-slots__week__slots .ta-courses__course {
  margin-bottom: 4px;
}

.ta-slots__week .ta-simple-list-item {
  background: #fff;
}

.ta-slots__week__slots.collapsed {
  max-height: 38px;
  overflow: hidden;
}

.ta-slots__week__slots .ta-slots__slot,
.ta-slots__week__slots .ta-hover-popup {
  width: calc(16.66% - 8px);
}

.ta-slots__week .ta-slots__week__text {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 2px 0;
}

.ta-slots__week .ta-slots__show-more {
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
}

.ta-slots__week .ta-slots__show-more:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  right: -12px;
  top: -1px;
}

.ta-slots__week .ta-slots__show-more.show-all:after {
  content: "\f106";
}

@media only screen and (max-width: 767px) {
  .ta-slots__week__slots .ta-slots__slot,
  .ta-slots__week__slots .ta-hover-popup,
  .ta-slots__week__slots .ta-slots__slot {
    width: calc(33.33% - 8px);
  }
  .ta-slots__week__slots.collapsed {
    min-height: 42px;
    max-height: 84px;
  }
  .ta-slots__week .ta-slots__show-more {
    padding: 5px 15px;
  }
  .ta-slots__week .ta-slots__show-more:after {
    right: 0;
    top: 4px;
  }
}
