.ta-language-selector {
  width: 52px;
  height: 32px;
  position: relative;
  z-index: 2;
}

.ta-language-selector__selector {
  width: 52px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  border: 1px solid #fff;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
}

.ta-language-selector__open .ta-language-selector__selector {
  background: #eceff3;
  color: #385f87;
  border-color: #385f87;
  border-radius: 3px 3px 0 0;
}

.ta-language-selector__selector img {
  width: 16px;
  height: 16px;
  margin: 8px 0 0 8px;
  border-radius: 50%;
  float: left;
}

.ta-language-selector__arrow {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
}

.ta-language-selector__options {
  width: 180px;
  height: 150px;
  display: none;
  overflow: auto;
  background: #eceff3;
  border: 1px solid #385f87;
  border-top: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 3;
  border-radius: 3px 0 3px 3px;
}

.ta-language-selector__open .ta-language-selector__options {
  display: block;
}

.ta-language-selector__title {
  display: none;
}

.ta-language-selector__options button {
  width: 100%;
  line-height: 15px;
  display: block;
  background: none;
  color: rgba(80, 90, 94, 0.6);
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  border: 0;
  border-bottom: 1px dotted #505a5e;
  padding: 6px 2px 6px 35px;
  position: relative;
  -ms-transition: background 0.2s ease, color 0.2s ease;
  -webkit-transition: background 0.2s ease, color 0.2s ease;
  -moz-transition: background 0.2s ease, color 0.2s ease;
  transition: background 0.2s ease, color 0.2s ease;
  cursor: pointer;
}

.ta-language-selector__options button:hover {
  background: rgba(56, 95, 135, 0.1);
  color: #385f87;
}

.ta-language-selector__options button:last-child {
  border: 0;
}

.ta-language-selector__options button img {
  width: 26px;
  height: 16px;
  position: absolute;
  left: 2px;
  top: 6px;
}

@media only screen and (max-width: 767px) {
  .ta-language-selector__options {
    width: auto;
    height: auto;
    background: #fff;
    border: 0;
    padding: 16px;
    position: fixed;
    left: 16px;
    top: 16px;
    bottom: 16px;
    right: 16px;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.26) 0 0 8px;
    border-radius: 3px;
  }
  .ta-language-selector__options button {
    line-height: 24px;
    background: #f4f3f3;
    color: #505a5e;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #f4f3f3;
    padding: 8px 8px 8px 40px;
    margin-top: 8px;
    border-radius: 3px;
    position: relative;
  }
  .ta-language-selector__options button:last-child {
    border: 1px solid #f4f3f3;
  }
  .ta-language-selector__options button:hover {
    border: 1px solid #505a5e;
  }
  .ta-language-selector__options button img {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    top: 8px;
  }
  .ta-language-selector__title {
    line-height: 24px;
    display: block;
    color: #505a5e;
    font-size: 18px;
    font-weight: 300;
    padding: 0 40px 16px 0;
    position: relative;
  }
  .ta-language-selector__close {
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #505a5e;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    -ms-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }
  .ta-language-selector__close:hover {
    color: #cb3b43;
  }
  .ta-language-selector.ta-language-selector__open:after {
    background: rgba(255, 255, 255, 0.8);
    content: " ";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}
