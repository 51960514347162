.ta-auth-confirmation__container {
  height: 351px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ta-auth-confirmation__inner {
  width: 100%;
  padding: 0 28px;
}

.ta-auth-confirmation__code_field {
  width: 240px;
  margin: 0 auto 24px auto;
}

.ta-auth-confirmation__buttons {
  text-align: center;
  margin: 0 -4px;
}

.ta-auth-confirmation__buttons .ta-cta-fluid {
  margin: 0 4px;
}
