.ta-auth-register__form {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 0 28px;
}

.ta-auth-register__form .ta-auth-register__form__container {
  width: 100%;
  padding: 16px 0;
  margin: auto;
}

.ta-auth-register__form .ta-row {
  margin-bottom: 3px;
}

.ta-auth-register__checkbox {
  margin: 8px 0 16px 0;
}

.ta-auth-register {
  display: flex;
  align-items: center;
}

.ta-auth-register .ta-note-box .ta-text-box {
  margin-bottom: 8px;
}

.ta-auth-register__checkbox ~ .ta-row .ta-col .ta-form-error.active {
  margin: -5px 0 8px 0;
}

.ta-auth-register__password-type-toggle-btn {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #505a5e;
}

.ta-auth-register__password-field input {
  padding-right: 32px;
}