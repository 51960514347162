.ta-radio {
  display: inline-block;
  color: #505a5e;
  font-size: 13px;
  font-weight: 300;
  line-height: 19px;
  min-height: 14px;
  position: relative;
  padding-left: 25px;
  user-select: none;
}

.ta-radio:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  color: #385f87;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 17px;
  border: solid 1px rgba(80, 90, 94, 0.6);
  border-radius: 18px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.ta-radio.active:before {
  content: "";
  background: #385f87;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  display: block;
}

.ta-radio.focused:after {
  border-color: #385f87;
}

.ta-radio.disabled:after {
  background-color: #e6e6e7;
  border-color: rgba(80, 90, 94, 0.6);
}

.ta-radio-field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.ta-radio-wrapper.inline {
  display: inline-block;
  padding-right: 15px;
}

.ta-radio .ta-hint-text {
  margin-top: 0;
}
