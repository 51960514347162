.ta-payments {
  padding-top: 100px;
}

.ta-payments__title {
  font-size: 14px;
  font-weight: 500;
  color: #505a5e;
  font-weight: 400;
  margin: 10px 0 24px 0;
  text-align: center;
}

.ta-payments__text {
  font-size: 14px;
  color: #505a5e;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.ta-payments__item {
  margin-bottom: 4px;
}

.ta-payments__item .ta-simple-list-item {
  padding-left: 50px;
}

.ta-payments__item__icon {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
  font-size: 24px;
  opacity: 0.5;
}

.ta-payments__item .ta-simple-list-item__buttons button {
  width: 24px;
  height: 44px;
  line-height: 44px;
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 0;
}
